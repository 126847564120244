<ion-content>
  <ng-container *ngIf="order">
    <ion-row style="display: flex;justify-content: center;margin-top: 150px;padding: 20px">
      {{'YOUR_ORDER_ADDED_SUCCESSFULLY' | translate}}
    </ion-row>
    <ion-img src="../../../../assets/icon/checkmark.gif"></ion-img>
  </ng-container>
  <ng-container *ngIf="!order">
    <ion-row style="display: flex;justify-content: center;margin-top: 150px;padding: 20px">
      {{'FAILED_IN_ADDING_YOUR_ORDER' | translate}}
    </ion-row>
    <ion-img src="../../../../assets/icon/failed_process.gif"></ion-img>
  </ng-container>
  <ion-row style="display: flex;justify-content: center;margin-top: 20px;position: fixed;right: 0px;left: 0px;bottom: 20px;">
    <ion-button style="width: 100%;height: 50px;border-radius: 8px;" (click)="openProductsPage()">
      {{'ORDER_MORE' | translate}}
    </ion-button>
  </ion-row>
</ion-content>