import * as fromProductVariantActions from '../actions';

import { ProductVariantState, initialState, productVariantAdapter } from '../state/product-variant.state';

export function ProductVariantReducer(
  state = initialState,
  action: fromProductVariantActions.ProductVariantActions
): ProductVariantState {
  switch (action.type) {
    case fromProductVariantActions.ProductVariantActionType.LOAD_HTTP: {
      return {
        ...state,
        loading: true
      };
    }

    case fromProductVariantActions.ProductVariantActionType.UPDATE_OFFSET: {
      return {
        ...state,
        offset: state.offset + state.limit
      };
    }

    case fromProductVariantActions.ProductVariantActionType.UPDATE_LIMIT: {
      return {
        ...state,
        limit: action.limit
      };
    }

    case fromProductVariantActions.ProductVariantActionType.UPDATE_DATA_LENGTH: {
      return {
        ...state,
        offset: action.dataLength
      };
    }

    case fromProductVariantActions.ProductVariantActionType.LOAD_HTTP_SUCCESS: {
      // add countrys to entities
      const offset = state.offset + action.payload.length;
      if (state.offset === 0) {
        return {
          ...productVariantAdapter.addAll(action.payload, state),
          loading: false,
          offset
        };
      } else {
        return {
          ...productVariantAdapter.upsertMany(action.payload, state),
          offset,
          loading: false
        };
      }
    }

    case fromProductVariantActions.ProductVariantActionType.LOAD_HTTP_FAIL: {
      return {
        ...state,
        loading: false
      };
    }

    case fromProductVariantActions.ProductVariantActionType.ADD_HTTP: {
      return {
        ...state,
        loading: true
      };
    }

    case fromProductVariantActions.ProductVariantActionType.UPDATE_SEARCH: {
            return {
              ...state,
              search: action.search,
              offset: 0
            };
          }          
  }
  return state;
}
