<ion-header *ngIf="!platform.is('desktop')" [translucent]="true">
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-button (click)="back()">
        <ion-icon name="arrow-back-outline"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-title *ngIf="product && product.name">
      {{product.name}}
    </ion-title>
  </ion-toolbar>
</ion-header>
<span *ngIf="product && !platform.is('desktop')">

  <ion-fab [ngStyle]="{'direction':direction == 'rtl'?'ltr':'rtl'}"
    style="position: fixed;left: 20px;right: 20px;bottom: 65px;" vertical="bottom" slot="fixed">
    <ion-fab-button style="--background: #1BD741;;" *ngIf="platform.is('desktop')"
      href="https://web.whatsapp.com/send?phone={{customerPhone}}&text={{whatsappMessage}}">
      <ion-icon name='logo-whatsapp'></ion-icon>
    </ion-fab-button>
    <ion-fab-button style="--background: #1BD741;;" *ngIf="!platform.is('desktop')"
      href="https://wa.me/+{{customerPhone}}?text={{whatsappMessage}}">
      <ion-icon name='logo-whatsapp'></ion-icon>
    </ion-fab-button>
  </ion-fab>
  <ion-row>
    <ion-col style="display: flex;justify-content: center;" size="12">
      <img [ngStyle]="{'width': !platform.is('desktop')?'100%':'50%'}" style="height: 100%;"
        [src]="env.url+'/web/image/storex_modules.product/'+productId+'/image/400x400'" alt="" />
    </ion-col>
  </ion-row>
  <ion-row style="font-size: 20px;margin-inline: 20px;margin-top: 20px;">
    <ion-label>
      {{'PRICE:' | translate}}
    </ion-label>
    <ion-label style="font-size: 20px;margin-inline: 20px;color: #F3A31A">
      {{product.price}}{{product.currency[0]}}
    </ion-label>
  </ion-row>

  <ion-row style="padding: 20px;" *ngIf="product.meta_description">
    <ion-col
      [ngClass]="{'limitTextHeight': !isReadMoreDesc && product.meta_description.length>(sharedService.innerWidth/2.11)}">
      <span style="font-size: 0.8rem;">
        {{product.meta_description}}
      </span>
    </ion-col>
    <ion-col style="display: flex;align-items: center;"
      *ngIf="product.meta_description.length>(sharedService.innerWidth/2.11)" size="auto">
      <ion-button size="small" fill="clear" (click)="isReadMoreDesc=!isReadMoreDesc">
        <ion-icon *ngIf="!isReadMoreDesc" name="caret-forward-outline"></ion-icon>
        <ion-icon *ngIf="isReadMoreDesc" name="caret-down-outline"></ion-icon>
      </ion-button>
    </ion-col>
  </ion-row>

  <ion-row *ngIf="categories">
    <ng-container *ngFor="let category of categories">
      <ion-label
        style="margin-inline: 20px;font-size: 20px;margin-top: 20px;color: #F3A31A;text-transform: capitalize;letter-spacing: 1.14px;background-color: #f3a31a4f;border-radius: 5px">
        {{category.name}}</ion-label>
    </ng-container>
  </ion-row>

  <ion-row style="display: flex;justify-content: center;margin-top: 20px;">
    <ion-button [ngStyle]="{'position': !isReadMoreDesc && !platform.is('desktop')?'fixed':'unset'}"
      style="width: 95%;height: 50px;border-radius: 8px;bottom:0px" (click)="openOrderForm(product.id)">
      {{'ORDER_NOW' | translate}}
    </ion-button>

  </ion-row>
</span>

<span *ngIf="product && platform.is('desktop')">
  <ion-row>
      <ion-icon (click)="back()" style="font-size: 25px;" name="arrow-back-outline"></ion-icon>
  </ion-row>
  <ion-row style="justify-content: center;margin-block: 20px;">
    <ion-img [src]="env.url+'/web/image/res.company/'+ 1 +'/logo/300x100'"></ion-img>
      
  </ion-row>
  <ion-grid style="background-color: #F5F5F5;padding-block: 73px;">
    <ion-row>
      <ion-col size="7">
        <div style="border-bottom: 2px solid #3B40461C;margin-inline: 20px;">
          <ion-row style="display: flex;align-items: center;margin-bottom: 20px;">
          <ion-col [ngStyle]="{'font-size':(sharedService.innerWidth<900?12:sharedService.innerWidth/80).toString()+'px'}"
            style="letter-spacing: 1.71px;color: #1E2329;text-transform: capitalize;opacity: 1;line-height: 30px;"
            size="5">
            {{product.name}}
          </ion-col>
          <ion-col style="display: flex;justify-content: flex-end;" size="7">
            <ng-container *ngFor="let category of categories">
              <ion-label [ngStyle]="{'font-size':(sharedService.innerWidth<900?12:sharedService.innerWidth/80).toString()+'px'}"
                style="margin-inline: 10px;color: #F3A31A;text-transform: capitalize;letter-spacing: 1.14px;background-color: #f3a31a4f;border-radius: 5px">
                {{category.name}}</ion-label>
            </ng-container>
          </ion-col>
        </ion-row>
      </div>
        <ion-row style="padding: 20px;" *ngIf="product.meta_description">
          <ion-col
            [ngClass]="{'limitTextHeightweb': !isReadMoreDesc && product.meta_description.length>(sharedService.innerWidth/2.11)}">
            <span style="font: 21px/27px Cairo">
              {{product.meta_description}}
            </span>
          </ion-col>
          <ion-col style="display: flex;align-items: flex-start;"
            *ngIf="product.meta_description.length>(sharedService.innerWidth/2.11)" size="auto">
            <ion-button size="small" fill="clear" (click)="isReadMoreDesc=!isReadMoreDesc">
              <ion-icon *ngIf="!isReadMoreDesc" name="caret-forward-outline"></ion-icon>
              <ion-icon *ngIf="isReadMoreDesc" name="caret-down-outline"></ion-icon>
            </ion-button>
          </ion-col>
        </ion-row>
        <ion-row style="font: 27px/24px Cairo;margin-inline: 20px;margin-top: 20px;">
          <ion-label>
            {{'PRICE:' | translate}}
          </ion-label>
          <ion-label style="font-size: 20px;margin-inline: 20px;color: #F3A31A">
            {{product.price}}{{product.currency[0]}}
          </ion-label>
        </ion-row>
        <ion-row *ngIf="productVariants && productVariants.length>1" style="padding-inline: 20px;margin-top: 20px;">
          <ion-label
            style="text-transform: capitalize;letter-spacing: 1.33px;color: #F3A31A;margin-bottom: 20px;font: 27px/24px Cairo;"
            position="stacked">{{'VARIANTS' | translate}}</ion-label>
          <ion-select 
            style="border-radius: 8px;border: 1px solid #3B4046;width: 100%;height: 46px;padding: 10px;"
             mode="ios" placeholder="{{'SELECT_VARIANT' | translate}}" (ionChange)="selectVariant($event)">
            <ion-select-option *ngFor="let variant of productVariants" [value]="variant">{{
              variant.name}}</ion-select-option>
          </ion-select>
        </ion-row>
        <ion-row *ngIf="productVariants && productVariants.length == 1" style="padding-inline: 20px;margin-top: 20px;">
          <ion-label
            style="text-transform: capitalize;letter-spacing: 1.33px;color: #F3A31A;margin-bottom: 20px;font: 27px/24px Cairo;"
            position="stacked">{{'VARIANTS' | translate}}</ion-label>
          <ion-row style="font: 27px/24px Cairo;width: 100%;height: 46px;padding: 10px;">
            {{selectedVariant.name}}
          </ion-row>
        </ion-row>
        <ion-row style="padding-inline: 20px;;margin-top: 20px;"  *ngIf="selectedVariant">
          <ion-label
            style="text-transform: capitalize;letter-spacing: 1.33px;color: #F3A31A;margin-bottom: 10px;font: 27px/24px Cairo;"
            position="stacked">{{'STOCK' | translate}}</ion-label>
          <ion-row style="border-radius: 8px;border: 1px solid #3B4046;width: 100%;height: 46px;">
            <ion-col size="3" style="display: flex;justify-content: center;align-items: center;">
              <ion-row>
                <ion-icon style="margin-inline: 15px;margin-bottom: 5px;" (click)="qtyPlus()"
                  name="add-outline"></ion-icon>
              </ion-row>
            </ion-col>
            <ion-col size="2.5"></ion-col>
            <ion-col style="margin-block: auto;display: flex;justify-content: space-around;" size="1">
              <ion-input type="tel" [value]="quantity"  (ionInput)="onInput($event)">
              </ion-input>
            </ion-col>
            <ion-col size="2.5"></ion-col>
            <ion-col style="display: flex;justify-content: center;align-items: center;" size="3">
              <ion-row>
                <ion-icon style="margin-inline: 15px;margin-bottom: 5px;" (click)="qtyMenus()"
                  name="remove-outline"></ion-icon>
              </ion-row>
            </ion-col>

          </ion-row>
        </ion-row>
        <ion-row style="display: flex;justify-content: space-between;;margin-top: 20px;padding-inline: 20px;">
          <ion-button [ngStyle]="{'width':(sharedService.innerWidth<923?sharedService.innerWidth/12:sharedService.innerWidth/20).toString()+'px'}"
          style="height: 50px;border-radius: 8px;bottom:0px" href="https://web.whatsapp.com/send?phone={{customerPhone}}&text={{whatsappMessage}}">
          <ion-icon [ngStyle]="{'font-size':(sharedService.innerWidth/40).toString()+'px'}" name='logo-whatsapp'></ion-icon>
        </ion-button>
          <ion-button [ngStyle]="{'width':(sharedService.innerWidth<923?sharedService.innerWidth/4:sharedService.innerWidth/2.2).toString()+'px'}"
            style="height: 50px;border-radius: 8px;bottom:0px" (click)="openOrderForm(product.id)">
            {{'ORDER_NOW' | translate}}
          </ion-button>
        </ion-row>
      </ion-col>
      <ion-col size="5">
        <ion-row>
          <ion-col style="display: flex;justify-content: center;" size="12">
            <img style="width: 80%;"
              [src]="imgSource" alt="" />
          </ion-col>
        </ion-row>
        <ion-row style="display: flex;justify-content: center;">
          <ion-slides *ngIf="product.variant_image_ids.length > 0" pager="false" scrollbar="true" [options]="{slidesPerView:5.5}" style="padding-inline: 20px;width: 80%;">
            <ion-slide style="display: block;" *ngFor="let attachment of product.variant_image_ids">
              <ion-row>
                <ion-col (click) = "selectImage(attachment,'variant')" style="display: flex;justify-content: center;" size="12">
                  <img style="height: 100%;"
                    [src]="env.url+'/web/image/storex_modules.variant_image/'+attachment+'/image/400x400'" alt="" />
                </ion-col>
              </ion-row>
            </ion-slide>
            <ion-slide style="display: block;" >
              <ion-row>
                <ion-col (click) = "selectImage(productId,'product')" style="display: flex;justify-content: center;" size="12">
                  <img style="height: 100%;"
                    [src]="env.url+'/web/image/storex_modules.product/'+productId+'/image/400x400'" alt="" />
                </ion-col>
              </ion-row>
            </ion-slide>
          </ion-slides>
        </ion-row>

      </ion-col>
    </ion-row>

    <ion-row *ngIf="categoryProducts && categoryProducts.length > 0" style="margin-top: 80px;padding-inline: 20px;font: 31px/21px Cairo;margin-bottom: 30px;">
      {{'WE_RECOMMEND' | translate}}
     </ion-row>
     <ion-slides *ngIf="categoryProducts && categoryProducts.length > 0" pager="false" scrollbar="true" [options]="{slidesPerView:5.5}" style="padding-inline: 20px;">
       <ion-slide style="display: block;" *ngFor="let product of categoryProducts">
         <ion-row>
           <ion-col  (click)="openQuickOrder(product.id)" size="12"
             style="text-align: center;">
             <img
               style="border-radius: 4px; border:1px solid var(--ion-color-primary); vertical-align: middle; background: white;width: 100%;"
               [src]="env.url+'/web/image/storex_modules.product/'+product.id+'/image/400x400'" alt="" />
             <br />
             <br />
             <ion-label style="font: normal normal 600 20px/21px Cairo;color: #1E2329;text-transform: capitalize; overflow: hidden;text-overflow: ellipsis;line-height: 20px;">
               {{product.name}}
             </ion-label>
             <br />
             <br />
             <ion-label
               style="font: normal normal 600 21px/21px Cairo;letter-spacing: 0.63px;color: #F3A31A;text-transform: capitalize;">{{product.price}}{{product.currency[0]}}</ion-label>
           </ion-col>
         </ion-row>
        
       </ion-slide>
     </ion-slides>
  </ion-grid>


</span>