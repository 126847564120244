<ion-grid *ngIf="selectedCategoryId == undefined || childCategories.length == 0">
  <ion-spinner *ngIf="isLoading"color="primary" name="lines"></ion-spinner>
  <ng-container *ngIf="products.length > 0">
    <ion-row>
      <ion-col *ngFor="let product of products" (click)="openQuickOrder(product.id)" size="3"
        style="text-align: center;">
        <ion-row>
          <img
          style="border-radius: 4px; border:1px solid var(--ion-color-primary); vertical-align: middle; background: white;width: 100%;"
          [src]="env.url+'/web/image/storex_modules.product/'+product.id+'/image/400x400'" alt="" />
        </ion-row>
        <ion-row  style="display: flex;justify-content: center;">
          <ion-label style="font: normal normal 600 20px/21px Cairo;color: #1E2329;text-transform: capitalize; overflow: hidden;text-overflow: ellipsis;height: 44px;margin-top: 10px;">
            {{product.name}}
          </ion-label>
        </ion-row>
        <ion-row style="display: flex;justify-content: center;margin-bottom: 20px;">
          <ion-label
          style="font: normal normal 600 21px/21px Cairo;letter-spacing: 0.63px;color: #F3A31A;text-transform: capitalize;">{{product.price}}{{product.currency[0]}}</ion-label>
        </ion-row>
      </ion-col>
    </ion-row>
  </ng-container>
</ion-grid>
<span *ngIf="childCategories.length > 0">
  <app-default-child-category [selectedStoreValue]="selectedStoreValue" [categoryId]="selectedCategoryId"></app-default-child-category>
  <ng-container *ngFor="let category of childCategories">
    <span *ngIf="category.product_ids.length > 0">
      <app-default-child-category [selectedStoreValue]="selectedStoreValue" [categoryId]="category.id"></app-default-child-category>
    </span>
  </ng-container>
</span>
<ion-infinite-scroll *ngIf="childCategories.length==0" threshold="10px" (ionInfinite)="loadMoreProducts($event)">
  <ion-infinite-scroll-content loadingText="{{ 'LOADING' | translate }}"></ion-infinite-scroll-content>
</ion-infinite-scroll>