import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { CategoryActionsType } from '../actions';
import * as CategoryActions from '../actions';
import { switchMap, map, catchError, withLatestFrom } from 'rxjs/operators';
import { of } from 'rxjs';
import { OdooAPI } from 'src/app/services/odoo/services/odooAPI';
import { Store } from '@ngrx/store';
import { CategoryState } from '../state';
import * as CategorySelectors from '../selectors';

@Injectable()
export class CategoryEffects {
  constructor(
    private actions$: Actions,
    private odooAPI: OdooAPI,
    private CategoryStore: Store<CategoryState>,
    ) {}

  @Effect()
  loadHttp$ = this.actions$.pipe(
    ofType(CategoryActionsType.LOAD_HTTP),
    switchMap((loadHTTPAction: CategoryActions.LoadHttp) => {
      
      return of(loadHTTPAction.payload);
    }),
    withLatestFrom(     
      this.CategoryStore.select(CategorySelectors.selectOffset),
      this.CategoryStore.select(CategorySelectors.selectLimit),
      this.CategoryStore.select(CategorySelectors.selectSearch),
    ),
    switchMap(([payload]) => {
      
      return this.odooAPI
        .loadPublicRecords(payload)
        .pipe(
          map(
            (data: any) => {
              
              return new CategoryActions.LoadHttpSuccess(data);
            },
            err => {
              return new CategoryActions.LoadHttpFail(err);
            }
          ),
        catchError(err => {
          return of(new CategoryActions.LoadHttpFail(err));
        })
        )
    })
  );

  @Effect()
  loadPublicHttp$ = this.actions$.pipe(
    ofType(CategoryActionsType.LOAD_PUBLIC_HTTP),
    switchMap((loadHTTPAction: CategoryActions.LoadPublicHttp) => {
      return of(loadHTTPAction);
    }),
    switchMap((payload: any) => {
      return this.odooAPI.loadPublicRecords(payload).pipe(
        map(
          (data: any) => {
            return new CategoryActions.LoadPublicHttpSuccess(data);
          },
          err => {
            return new CategoryActions.LoadPublicHttpFail(err);
          }
        ),
        catchError((err: any) => {
          return of(new CategoryActions.LoadPublicHttpFail(err));
        })
      );
    })
  );
  @Effect()
  updateSearch$ = this.actions$.pipe(
    ofType(CategoryActionsType.UPDATE_SEARCH),
    switchMap((updateSearchAction: CategoryActions.UpdateSearch) =>
      of(updateSearchAction.search)
    ),
    switchMap((search: any) => {
      return of(new CategoryActions.LoadHttp({}));
    })
  );
}
