import { Injectable } from '@angular/core';
import { OdooJsonRPC } from './odooJsonRPC';
import {  from, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ErrorState } from 'src/app/stores/error/store';
import { Store } from '@ngrx/store';
import { environment } from 'src/environments/environment';
import { modelURL, PublicOdooRequest } from '../models/ModelRemoteOdoo';
import { Storage } from '@ionic/storage';
import { SharedService } from 'src/app/services/sharedService';


@Injectable()
export class OdooAPI {
  last = 0;
  companyInfo: any;
  countryCode: string;
  constructor(
    private odooJsonRPC: OdooJsonRPC, // private fileOperations: FileOperations,
    private httpClient: HttpClient,
    private errorStore: Store<ErrorState>,
    private storage:Storage,
    private SharedService: SharedService


  ) {
  
  }

  loadAreaPublic(countryCode?) {
    let headers = new HttpHeaders().set('content-type', 'application/json');
    return this.httpClient.post(
      environment.url + '/storex_modules/area',
      {
        jsonrpc: '2.0',
        params: {
          country_code: countryCode?countryCode:this.countryCode,
        },
      },
      { headers: headers }
    ).pipe(map((data:any)=>{
      
      if(data.result)
        return data.result
      return false
    }));
  }

  loadSubAreaPublic(areaId) {
    let headers = new HttpHeaders().set('content-type', 'application/json');
    return this.httpClient.post(
      environment.url + '/rb_delivery/sub_area',
      {
        jsonrpc: '2.0',
        params: {
          area_id: areaId,
        },
      },
      { headers: headers }
    ).pipe(map((data:any)=>{
      if(data.result)
        return data.result
      return false
    }));
  }

  loadCountry(uid, offset, ids?: [], limit?: number, search?: any) {
    const modelName = 'rb_delivery.country';
    const fields = ['id', 'code', 'name'];
    let domain = [];
    if (search.length !== 0) {
      domain = [search];
    } else {
      domain = [];
    }
    if (!limit) {
      limit = 200;
    }
    return from(
      this.odooJsonRPC.searchRead(
        modelName,
        domain,
        fields,
        limit,
        offset,
        null
      )
    )
      .pipe(
        map((data) => {
          if (data.body.error) {
            const error = {
              message: 'Error getting country record',
              model: modelName,
              odoo: data,
            };
            throwError(JSON.stringify(error));
          }

          return data.body.result;
        })
      )
      .pipe(
        catchError((error: any) => {
          return throwError(error);
        })
      );
  }

  loadCountryPublic() {
    return this.httpClient.get(
      this.companyInfo.website_url + '/rb_delivery/country'
    );
  }

   loadPublicRecords(requestData:PublicOdooRequest){
    const model = requestData.model
    var dataTosend: any = 
    { 
      model: requestData.model, 
      domain: requestData.domain, 
      offset: requestData.offset, 
      limit: requestData.limit, 
      order:requestData.order,
      fields: requestData.fields,
      country_code:this.countryCode
    };
    if(this.SharedService.userInfo && this.SharedService.userInfo['id']){
      dataTosend['user_id'] = this.SharedService.userInfo['id']
    }
    if(requestData.search && !requestData.domain){
      dataTosend['domain'] = requestData.search
    }
    // This is a fix to fetch only published products 
    // TODO better way to do this 
    
    return this.httpClient
      .post(
        environment.url + modelURL[model],
        {params : dataTosend })
      .pipe(
        map((data: any) => {
          
          if ('error' in data){
            throw new Error(JSON.stringify(data.error));
          } else if('result' in data){
            if(model=="product.template")
            return data.result
            else
            return data.result.records;
          }
        })
      );
  }

  addPublicOrder(order_data: any) {
    order_data['country_code']=this.countryCode
    return this.httpClient
      .post(
        environment.url + '/storex/create/order/',
        {params :order_data}
      )
      .pipe(
        map((data: any) => {
          
          if ('error' in data){
            throw new Error(JSON.stringify(data.error.data.message));
          } else if('result' in data){
              return data.result;
          }else{
              
              throw new Error(JSON.stringify(data.result.message));
          }
          
        })
      );
  }

  getIpAddress(){
    return this.httpClient.get("https://api64.ipify.org/?format=json").pipe(map((res:any)=>{
      
      return res.ip
    }));
  }

  getCountryByIp(ip){
    return this.httpClient.get("https://ipapi.co/"+ip+"/json/").pipe(map((res:any)=>{
      
      this.countryCode = res.country;
    }));
  }

  getClientConfigPublic() {
    return this.httpClient.get(
      environment.url + '/rb_delivery/get_client_config'
    );
  }

  async register(user) {
    user['db'] = environment.db
    const dataTosend = { params: user };
    let context_info= await this.storage.get('context_info').catch(err=>{ console.log('Getting session_id token from ionic storage not wrking') })
    let headers= new HttpHeaders({
      'Content-Type': 'application/json; charset=utf-8',
        })

      if(context_info && context_info.session_id)
      {
        headers= new HttpHeaders({
          'Content-Type': 'application/json; charset=utf-8',
          'X-Openerp-Session-Id': context_info.session_id
        })
      }
      headers.set('Accept-Language', '')
    return this.httpClient
      .post(
        environment.url+'/rb_delivery/register',
        dataTosend,{headers:headers,withCredentials:true,reportProgress:true}
      )
      .pipe(
        map((data: any) => {
            if(data && data.result && data.result[0].message){
              return  data.result[0].message
            }
            else if(data.error && data.error.data && data.error.data.arguments && data.error.data.arguments[0]){
              return data.error.data.arguments[0]
            }else if(data.error){
              throw new Error(JSON.stringify(data.error));
            }
          return true;
        })
      );
  }


  async logIn(user) {
    let context_info= await this.storage.get('context_info').catch(err=>{ console.log('Getting session_id token from ionic storage not wrking') })
    let headers= new HttpHeaders({
      'Content-Type': 'application/json; charset=utf-8',
        })

      if(context_info && context_info.session_id)
      {
        headers= new HttpHeaders({
          'Content-Type': 'application/json; charset=utf-8',
          'X-Openerp-Session-Id': context_info.session_id
        })
      }

    const db = environment.db
    const login = user['mobile_number']
    const password = user['password']
    const params = {
      db,
      login,
      password,
    };
    return this.httpClient
      .post(
        environment.url+'/web/session/authenticate_mobile',{params: params},{headers:headers,withCredentials:true,reportProgress:true},
      )
      .pipe(
        map( (data: any) => {
          if(data && data.result && data.result[0].message){
            return  data.result[0].message
          }
            if(data.error && data.error.data && data.error.data.arguments && data.error.data.arguments[0]){
              return data.error.data.arguments[0]
            }else if(data.error){
              throw new Error(JSON.stringify(data.error));
            }
        })
      );
  }

  getMeta() {
    return this.httpClient.get(
      environment.url + '/olivery_commerce/get_meta'
    );
  }

}
