import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-templates-selector',
  templateUrl: './templates-selector.component.html',
  styleUrls: ['./templates-selector.component.scss'],
})
export class TemplatesSelectorComponent implements OnInit {
  
  @Input() type:string;
  @Input() template:string;
  @Input() products:any;
  @Input() categories:any;
  @Input() banners:any;
  constructor(
    private translate: TranslateService,

  ) { }

  ngOnInit() {}

}
