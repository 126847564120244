import { Action } from '@ngrx/store';
import { IBanner } from '../../model';

// HTTP CRUD

//  CRUD
export enum BannerActionsType {
  LOAD_HTTP = '[Banner] Load Http',
  LOAD_HTTP_SUCCESS = '[Banner] Load Http Success',
  LOAD_HTTP_FAIL = '[Banner] Load Http Fail',

  LOAD_PUBLIC_HTTP = '[Banner] Load Public Http',
  LOAD_PUBLIC_HTTP_SUCCESS = '[Banner] Load Http Public Success',
  LOAD_PUBLIC_HTTP_FAIL = '[Banner] Load Public Http Fail',

  ADD_HTTP = '[Banner] Add Http',
  UPDATE_HTTP = '[Banner] Update Http',
  ADD_UPDATE_HTTP_SUCCESS = '[Banner] Add Update Http Success',
  ADD_UPDATE_HTTP_FAIL = '[Banner] Add Update Http Fail',

  DELETE_HTTP = '[Banner] Delete Http',
  DELETE_HTTP_SUCCESS = '[Banner] Delete Http Success',
  DELETE_HTTP_FAIL = '[Banner] Delete Http Fail',

  UPDATE_DATA_LENGTH = '[Banner] Update Data Length',
  UPDATE_OFFSET = '[Banner] Update Offset',
  UPDATE_LIMIT = '[Banner] Update Limit',

  UPDATE_SEARCH = '[Banner] Update Search',

}

export class LoadHttp implements Action {
  readonly type = BannerActionsType.LOAD_HTTP;
  constructor(public payload: any) {}
}

export class LoadHttpSuccess implements Action {
  readonly type = BannerActionsType.LOAD_HTTP_SUCCESS;
  constructor(public payload: IBanner[]) {}
}
export class LoadHttpFail implements Action {
  readonly type = BannerActionsType.LOAD_HTTP_FAIL;
  constructor(public error: any) {}
}

export class LoadPublicHttp implements Action {
  readonly type = BannerActionsType.LOAD_PUBLIC_HTTP;
  constructor() {}
}

export class LoadPublicHttpSuccess implements Action {
  readonly type = BannerActionsType.LOAD_PUBLIC_HTTP_SUCCESS;
  constructor(public payload: IBanner[]) {}
}
export class LoadPublicHttpFail implements Action {
  readonly type = BannerActionsType.LOAD_PUBLIC_HTTP_FAIL;
  constructor(public error: any) {}
}

export class AddHttp implements Action {
  readonly type = BannerActionsType.ADD_HTTP;
  constructor(public payload: IBanner) {}
}

export class UpdateHttp implements Action {
  readonly type = BannerActionsType.UPDATE_HTTP;
  constructor(public payload: IBanner) {}
}

export class AddUpdateHttpSuccess implements Action {
  readonly type = BannerActionsType.ADD_HTTP;
  // comes from effect
  constructor(public payload: IBanner) {}
}
export class AddUpdateHttpFail implements Action {
  readonly type = BannerActionsType.ADD_UPDATE_HTTP_FAIL;
  constructor(public error: any) {}
}

export class DeleteHttp implements Action {
  readonly type = BannerActionsType.DELETE_HTTP;
  constructor(public countryId: number) {}
}

export class DeleteHttpSuccess implements Action {
  readonly type = BannerActionsType.DELETE_HTTP_SUCCESS;
  constructor(public payload: any) {}
}

export class DeleteHttpFail implements Action {
  readonly type = BannerActionsType.DELETE_HTTP_FAIL;
  constructor(public error: any) {}
}

export class UpdateDataLength implements Action {
  readonly type = BannerActionsType.UPDATE_DATA_LENGTH;
  constructor(public dataLength: number) {}
}

export class UpdateOffset implements Action {
  readonly type = BannerActionsType.UPDATE_OFFSET;
  constructor() {}
}
export class UpdateLimit implements Action {
  readonly type = BannerActionsType.UPDATE_LIMIT;
  constructor(public limit: number) {}
}
export class UpdateSearch implements Action {
  readonly type = BannerActionsType.UPDATE_SEARCH;
  constructor(public search: any) { }
}

export type BannerActions =
  | LoadHttp
  | LoadHttpSuccess
  | LoadHttpFail
  | LoadPublicHttp
  | LoadPublicHttpSuccess
  | LoadPublicHttpFail
  | AddHttp
  | UpdateHttp
  | AddUpdateHttpSuccess
  | AddUpdateHttpFail
  | DeleteHttp
  | DeleteHttpSuccess
  | DeleteHttpFail
  | UpdateDataLength
  | UpdateOffset
  | UpdateLimit
  | UpdateSearch;
