import { Component, Input, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { filter, take, takeUntil } from 'rxjs/operators';
import { ProductVariantState } from 'src/app/stores/product-variant/store/state';
import { ProductState } from 'src/app/stores/product/store';
import * as productSelector from 'src/app/stores/product/store/selectors';
import * as productVariantSelector from 'src/app/stores/product-variant/store/selectors';
import { environment } from 'src/environments/environment';
import { CategoryState } from 'src/app/stores/category/store';
import * as  CategorySelector from 'src/app/stores/category/store/selectors';
import { ModalController, Platform, ToastController } from '@ionic/angular';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { OdooAPI } from 'src/app/services/odoo/services/odooAPI';
import { SuccessOrderComponent } from '../success-order/success-order.component';
import { IProductVariant } from 'src/app/stores/product-variant/model';
import { Subject } from 'rxjs';
import { SharedService } from 'src/app/services/sharedService';
import { Storage } from '@ionic/storage';


@Component({
  selector: 'app-quick-order-form',
  templateUrl: './quick-order-form.component.html',
  styleUrls: ['./quick-order-form.component.scss'],
})
export class QuickOrderFormComponent implements OnInit {

  @Input() productId: any;
  product: any;
  productVariants: any;
  env = environment;
  categories: any;
  formGroup: FormGroup;
  validation_messages: any;
  selectedAreaId: any;
  selectedVariant: any;
  orderSuccess: boolean;
  areas: any[];
  quantity: number = 0;
  submitAttempt = false;
  destroyed$ = new Subject<any>();
  selectedAreaName: any;
  createUid: any;

  constructor(
    private productStore: Store<ProductState>,
    private productVarientStore: Store<ProductVariantState>,
    private categoryStore: Store<CategoryState>,
    private modalCtrl: ModalController,
    private formBuilder: FormBuilder,
    private translate: TranslateService,
    private odooApi: OdooAPI,
    private toastCtrl: ToastController,
    public platform: Platform,
    public sharedService:SharedService,
    private storage:Storage,

  ) { }

  ngOnInit() {
    this.initForm()
    if(this.selectedVariant){
      this.formGroup.get('product_id').setValue(this.selectedVariant.id)
    }
    this.loadProduct(this.productId)
    this.loadProductVariants(this.productId)
    this.loadCategories()
    this.loadAreas()
    this.formGroup.get('qty').setValue(this.quantity)
  }
  loadProduct(id) {
    this.productStore.select(productSelector.selectProductById(id)).pipe(filter(data => data && data.length > 0), take(1)).subscribe(data => {
      this.product = data[0]
    })
  }
  loadProductVariants(productId) {

    this.productVarientStore.select(productVariantSelector.selectProductVariantByProductId(productId)).pipe(filter(data => data && data.length > 0), take(1)).subscribe(data => {
      if(data && data.length > 0){
        this.productVariants = data
        if(this.productVariants.length == 1){
          if(this.productVariants[0].name != this.product.name){
            this.productVariants[0].name = this.productVariants[0].name.split('_')
            this.productVariants[0].name.shift()
            this.productVariants[0].name = this.productVariants[0].name.join()
          }
          this.selectedVariant = this.productVariants[0]
          this.formGroup.get('product_id').setValue(this.selectedVariant.id)

        }
        else{
          for(let variant of this.productVariants){
            if(variant.attribute_values_ids.length>0){
              variant.name = variant.name.split('_')
              if(variant.name.length > 0 && variant.name[0] == this.product.name ){
                variant.name.shift()
                variant.name = variant.name.join()
              }
            }
              
          }
        }
        }


    })
  }
  loadCategories() {
    if (this.product && this.product.category_ids) {
      this.categoryStore.select(CategorySelector.selectCategoryByIds(this.product.category_ids)).pipe(filter(data => data && data.length > 0), take(1)).subscribe(data => {

        this.categories = data
      })
    }
  }
  back() {
    this.modalCtrl.dismiss()
  }
  initForm() {
    this.formGroup = this.formBuilder.group({
      client_name: new FormControl(
        '',
        Validators.compose([Validators.required])
      ),

      client_mobile: new FormControl(
        '',
        Validators.compose([
          Validators.required,
          Validators.maxLength(10),
          Validators.minLength(10)
        ])
      ),

      client_area: new FormControl(
        '',
        Validators.compose([Validators.required])
      ),
      qty: new FormControl(
        '',
        Validators.compose([])
      ),
      client_address: new FormControl(
        '',
        Validators.compose([Validators.required])
      ),
      product_id: new FormControl(
        '',
        Validators.compose([Validators.required])
      ),
    });


    this.validation_messages = {
      client_name: [{ type: 'required', message: 'THIS_FIELD_IS_REQUIRED' }],
      client_mobile: [
        { type: 'required', message: this.translate.instant('THIS_FIELD_IS_REQUIRED') },
        {
          type: 'minlength',
          message: this.translate.instant('PHONE_MUST_BE_AT_LEAST_') + ' ' + 10 + ' ' + this.translate.instant('NUMBERS')
        },
        {
          type: 'maxlength',
          message: this.translate.instant('PHONE_MUST_BE_') + ' ' + 10 + ' ' + this.translate.instant('NUMBERS')
        },
        {
          type: 'pattern',
          message: this.translate.instant('YOUR_PHONE_MUST_CONTAIN_ONLY_NUMBERS')
        },],
      client_area: [{ type: 'required', message: 'THIS_FIELD_IS_REQUIRED' }],
      client_address: [{ type: 'required', message: 'THIS_FIELD_IS_REQUIRED' }],
      qty: [{ type: 'required', message: 'THIS_FIELD_IS_REQUIRED' }],
      product_id: [{ type: 'required', message: 'THIS_FIELD_IS_REQUIRED' }],

    }
  }

  selectArea(ev) {
    this.selectedAreaId = ev.detail.value
    this.formGroup.get('client_area').setValue(this.selectedAreaId)
  }

  selectVariant(ev) {
    this.selectedVariant = ev.detail.value
    this.formGroup.get('product_id').setValue(this.selectedVariant.id)

  }

  sendOrderForm() {
    this.submitAttempt = true
    if (this.formGroup.valid) {
      this.formGroup.get('client_mobile').setValue(String(this.formGroup.get('client_mobile').value))
      let values = {... this.formGroup.value}
      if(this.sharedService.userSharedLink){
        values['create_uid']=this.sharedService.userSharedLink
      }
      if(this.createUid){
        values['create_uid']=this.createUid
      }
      this.odooApi.addPublicOrder(values).subscribe(order => {
        const modal = this.modalCtrl.create({
          component: SuccessOrderComponent,
          componentProps: {
            order: order
          }
        })
        modal.then(mod => mod.present())
        this.modalCtrl.dismiss()
      })
    }
  }

  loadAreas() {

    this.odooApi.loadAreaPublic(this.odooApi.countryCode).pipe(take(1)).subscribe((areas: any[]) => {
      
      this.areas = areas
      this.checkUserInfo()
    })
  }

  qtyPlus() {
    
      this.quantity = this.quantity + 1
    this.formGroup.get('qty').setValue(this.quantity)
  }

  qtyMenus() {
    if(this.quantity>0)
      this.quantity = this.quantity - 1
      this.formGroup.get('qty').setValue(this.quantity)
    
  }

  compareById(o1, o2) {
    return o1.id === o2.id
  }

  checkUserInfo(){
   this.storage.get('user_info').then(data =>{
    if(data && data.length>0){
      let userInfo = data[0]
      if(userInfo['username']){
        this.formGroup.get('client_name').setValue(userInfo['username'])
      }
      if(userInfo['area_id']){
        this.selectedAreaId = userInfo['area_id'][0]
        this.formGroup.get('client_area').setValue(userInfo['area_id'][0])
      }
      if(userInfo['mobile_number']){
        this.formGroup.get('client_mobile').setValue(userInfo['mobile_number'])
      }
      if(userInfo['address']){
        this.formGroup.get('client_address').setValue(userInfo['address'])
      }
      if(userInfo['user_id'] && userInfo['user_id'].length > 0){
        this.createUid = userInfo['user_id'][0]
      }
    }
   })

  }

}
