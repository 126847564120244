import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { ProductActionType } from '../actions';
import * as ProductActions from '../actions';
import { switchMap, map, catchError, withLatestFrom, mergeMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { OdooAPI } from 'src/app/services/odoo/services/odooAPI';
import { Store } from '@ngrx/store';
import { ProductState } from '..';
import * as ProductSelectors from '../selectors';
import { productFields } from '../../model';

@Injectable()
export class ProductEffects {
  constructor(
    private actions$: Actions,
    private odooAPI: OdooAPI,
    private productStore: Store<ProductState>,
    ) {}

  @Effect()
  loadHttp$ = this.actions$.pipe(
    ofType(ProductActionType.LOAD_HTTP),
    mergeMap((loadHTTPAction: ProductActions.LoadHttp) => {
      return of(loadHTTPAction.payload);
    }),
    withLatestFrom(     
      this.productStore.select(ProductSelectors.selectOffset),
      this.productStore.select(ProductSelectors.selectLimit),
      this.productStore.select(ProductSelectors.selectSearch),
    ),
    mergeMap(([payload,offset,limit,search]) => {
      
      if(!payload.model)
        payload.model="storex_modules.product"
      if(!payload.offset && typeof payload.offset != "number")
        payload.offset=offset
      if(!payload.limit)
        payload.limit=limit
      if(!payload.search)
        payload.search=search
      if(!payload.fields)
        payload.fields=productFields
      return this.odooAPI
        .loadPublicRecords(payload)
        .pipe(
          map(
            (data: any) => {
              
              return new ProductActions.LoadHttpSuccess(data);
            },
            err => {
              return new ProductActions.LoadHttpFail(err);
            }
          ),
        catchError(err => {
          return of(new ProductActions.LoadHttpFail(err));
        })
        )
    })
  );

  @Effect()
  updateSearch$ = this.actions$.pipe(
    ofType(ProductActionType.UPDATE_SEARCH),
    switchMap((updateSearchAction: ProductActions.UpdateSearch) =>
      of(updateSearchAction.search)
    ),
    switchMap((search: any) => {
      return of(new ProductActions.LoadHttp({}));
    })
  );
}
