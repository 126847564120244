import { EntityState, createEntityAdapter } from '@ngrx/entity';
import { EntityAdapter } from '@ngrx/entity/src/models';
import { IBanner } from '../../model';

export interface BannerState extends EntityState<IBanner> {
  loading: boolean;
  uploading: boolean;
  offset: number;
  limit: number;
  dataLength: number;
  BannerRegister:any;
  search: any;
}

export const BannerAdapter: EntityAdapter<IBanner> = createEntityAdapter<IBanner>();

export const initialState: BannerState = BannerAdapter.getInitialState({
  uploading: false,
  loading: false,
  offset: 0,
  dataLength: 0,
  limit: 50,
  search: [],
  BannerRegister:[]
});
