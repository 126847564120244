import { Component, HostListener, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { OdooAPI } from './services/odoo/services/odooAPI';
import { Storage } from '@ionic/storage';
import { TranslateService } from '@ngx-translate/core';
import { Meta } from '@angular/platform-browser';  
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { filter, map, mergeMap, take } from 'rxjs/operators';
import { pipe } from 'rxjs';
import { environment } from 'src/environments/environment';
import { SEOService } from 'src/app/services/SEOservice';
import { SharedService } from './services/sharedService';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit {
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.sharedService.innerWidth = window.innerWidth;
  }
  countryCode
  env = environment
  productId: any;
  selectedTemplates: any;
  constructor(
    private odooApi: OdooAPI,
    private storage:Storage,
    private translate : TranslateService,
    private sharedService:SharedService,
    private route: ActivatedRoute,
    private meta: Meta
  ) {
    this.getMeta()
  }
  getMeta() {
    this.odooApi.getMeta().pipe(take(1)).subscribe((data:any)=>{
      try{
        for(let meta of data){
          this.meta.addTag({ name: meta.name, content: meta.content });
        }
      }
      catch{
        console.log('No Meta Tags!')
      }
    })
  }
  async ngOnInit(): Promise<void> {
    this.sharedService.innerWidth = window.innerWidth;
    if(window.location.href.includes('/user')){
      let userId = window.location.href.split('/user/')[1]
      this.sharedService.userSharedLink = userId
    }
    if(window.location.href.includes('/ar_SY')){
      this.translate.use('ar')
      document.documentElement.dir = 'rtl';
    }
    else if (window.location.href.includes('/en_US')){
      this.translate.use('en')
      document.documentElement.dir = 'ltr';
    }
    else{
      this.translate.use('ar')
      document.documentElement.dir = 'rtl';
    }
    await this.storage.create();
    this.storage.get('countryCode').then(countryCode=>{
      if(countryCode && countryCode!="auto"){
        this.odooApi.countryCode=countryCode
        this.countryCode=countryCode
      }else{
          this.odooApi.getIpAddress().subscribe(ip=>{
            this.odooApi.getCountryByIp(ip).subscribe(()=>{
              this.countryCode=this.odooApi.countryCode
            })
          })
      }
    })

    let data ={ 
      model: 'storex_commerce.template_selector', 
      domain: [], 
      offset: 0, 
      limit: 1, 
      order:null,
      fields: ['selected_landing_page_template','selected_filter_page_template','selected_package_page_template','selected_quick_order_template'],
      country_code:false
    }
    this.odooApi.loadPublicRecords(data).subscribe(data => {
          if(data && data.length>0)  {
            this.storage.set('selectedTemplates',data)
          }
    })

     this.storage.get('user_info').then(data =>{
      if (data && data.length>0){
        this.sharedService.userInfo = data[0]
      }
    })

    this.route.queryParams.subscribe(params => {
      if(params && params.domain)
      {
        let domain = JSON.parse(params.domain)
        if(domain && domain.length > 0){
          this.sharedService.productDomian.concat(params.domain)
        }
      }
    })

    }
    

  }
