import { createFeatureSelector, createSelector } from '@ngrx/store';

import { BannerState, BannerAdapter } from '../state/banner.state';

export const selectBannerState = createFeatureSelector<BannerState>('Banner');

export const getBannerEntities = (state: BannerState) => {
  // tslint:disable-next-line:no-unused-expression
  state.entities;
};

export const getBannerLoading = (state: BannerState) => state.loading;

export const getBannerUploading = (state: BannerState) => state.uploading;
export const getOffset = (state: BannerState) => state.offset;
export const getLimit = (state: BannerState) => state.limit;
export const getDataLength = (state: BannerState) => state.dataLength;
export const getBannerRegister = (state: BannerState) => state.BannerRegister;
export const getSearch = (state: BannerState) => state.search;


const {
  
  selectEntities,
  selectAll,
  selectTotal
} = BannerAdapter.getSelectors();

export const selectAllIDS = createSelector(
  selectBannerState,
  
);

export const selectAllData = createSelector(
  selectBannerState,
  selectAll
);

export const selectAllEntities = createSelector(
  selectBannerState,
  selectEntities
);

export const selectOffset = createSelector(
  selectBannerState,
  getOffset
);
export const selectDataLength = createSelector(
  selectBannerState,
  getDataLength
);

export const selectLimit = createSelector(
  selectBannerState,
  getLimit
);

export const selectDataBanneredById = createSelector(
  selectAllData,
  (data: any) => {
    data.sort((a, b) => b.id - a.id);
    return data;
  }
);
export const selectBannerById =(id) => createSelector (
  selectAllData,
  
  (data: any) => {
    return data.filter(Banner=>Banner.id == id);
  }
);
export const selectBannerRegister = createSelector(
  selectBannerState,
  getBannerRegister
);
export const selectSearch = createSelector(
  selectBannerState,
  getSearch
);