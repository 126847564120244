import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { environment } from 'src/environments/environment';

// const redirectUrl=environment.url.split('.').length>2

const routes: Routes = [
  {
    path: '',
    redirectTo: 'landing-page',
    pathMatch: 'full'
  },
  {
    path: 'landing-page',
    loadChildren: () => import('./pages/landing-page/landing-page.module').then( m => m.LandingPagePageModule)
  },
  {
    path: 'quick-order/:id/user/:userId/lang/:lang',
    loadChildren: () => import('./pages/quick-order/quick-order.module').then( m => m.QuickOrderPageModule),
  },
  {
    path: 'quick-order/:id',
    loadChildren: () => import('./pages/quick-order/quick-order.module').then( m => m.QuickOrderPageModule),
  },
  {
    path: 'quick-order/:id/user/:userId',
    loadChildren: () => import('./pages/quick-order/quick-order.module').then( m => m.QuickOrderPageModule),
  },
  {
    path: 'package-page',
    loadChildren: () => import('./pages/package-page/package-page.module').then( m => m.PackagePagePageModule)
  },
  {
    path: 'filter-page',
    loadChildren: () => import('./pages/filter-page/filter-page.module').then( m => m.FilterPagePageModule)
  },
  
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
