import { EntityState, createEntityAdapter } from '@ngrx/entity';
import { EntityAdapter } from '@ngrx/entity/src/models';
import { IProductVariant } from '../../model';

export interface ProductVariantState extends EntityState<IProductVariant> {
  loading: boolean;
  uploading: boolean;
  offset: number;
  limit: number;
  dataLength: number;
  countryRegister:any;
  search: any;
}

export const productVariantAdapter: EntityAdapter<IProductVariant> = createEntityAdapter<IProductVariant>();

export const initialState: ProductVariantState = productVariantAdapter.getInitialState({
  uploading: false,
  loading: false,
  offset: 0,
  dataLength: 0,
  limit: 50,
  search: [],
  countryRegister:[]
});
