import { Injectable } from "@angular/core";

@Injectable()
export class SharedService {
  public innerWidth: number;
  public productDomian :any=[];
  public selectedStoreValue : any;
  public userSharedLink : string;
  public userInfo : any;
  constructor(){
    this.productDomian = [['delivery_user_id.storex_sub_domain', '=',  window.location.hostname]]
  }
}