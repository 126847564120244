import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { ProductVariantActionType } from '../actions';
import * as ProductVariantActions from '../actions';
import { switchMap, map, catchError, withLatestFrom } from 'rxjs/operators';
import { of } from 'rxjs';
import { OdooAPI } from 'src/app/services/odoo/services/odooAPI';
import { Store } from '@ngrx/store';
import { ProductVariantState } from '../state';
import * as ProductVariantSelectors from '../selectors';

@Injectable()
export class ProductVariantEffects {
  constructor(
    private actions$: Actions,
    private odooAPI: OdooAPI,
    private productStore: Store<ProductVariantState>,
    ) {}

  @Effect()
  loadHttp$ = this.actions$.pipe(
    ofType(ProductVariantActionType.LOAD_HTTP),
    switchMap((loadHTTPAction: ProductVariantActions.LoadHttp) => {
      return of(loadHTTPAction.payload);
    }),
    withLatestFrom(     
      this.productStore.select(ProductVariantSelectors.selectOffset),
      this.productStore.select(ProductVariantSelectors.selectLimit),
      this.productStore.select(ProductVariantSelectors.selectSearch),
    ),
    switchMap(([payload]) => {
      return this.odooAPI
        .loadPublicRecords( payload)
        .pipe(
          map(
            (data: any) => {
              
              return new ProductVariantActions.LoadHttpSuccess(data);
            },
            err => {
              return new ProductVariantActions.LoadHttpFail(err);
            }
          ),
        catchError(err => {
          return of(new ProductVariantActions.LoadHttpFail(err));
        })
        )
    })
  );

  @Effect()
  updateSearch$ = this.actions$.pipe(
    ofType(ProductVariantActionType.UPDATE_SEARCH),
    switchMap((updateSearchAction: ProductVariantActions.UpdateSearch) =>
      of(updateSearchAction.search)
    ),
    switchMap((search: any) => {
      return of(new ProductVariantActions.LoadHttp({}));
    })
  );
}
