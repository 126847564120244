
<span *ngIf="!platform.is('desktop')">
  <ion-slides>
    <ion-slide *ngFor="let banner of banners">
      <img style="width: 100%;" [src]="env.url+'/web/image/storex_modules.banner/'+banner.id+'/image/300x100'" />
    </ion-slide>
  </ion-slides>

</span>
<span *ngIf="platform.is('desktop')">
  <ion-slides>
    <ion-slide *ngFor="let banner of banners">
      <img style="width: 100%;" [src]="env.url+'/web/image/storex_modules.banner/'+banner.id+'/image/600x200'" />
    </ion-slide>
  </ion-slides>

</span>
 