import { Action } from '@ngrx/store';
import { IProduct } from '../../model';

// HTTP CRUD

//  CRUD
export enum ProductActionType {
  LOAD_HTTP = '[Product] Load Http',
  LOAD_HTTP_SUCCESS = '[Product] Load Http Success',
  LOAD_HTTP_FAIL = '[Product] Load Http Fail',

  ADD_HTTP = '[Product] Add Http',
  UPDATE_HTTP = '[Product] Update Http',
  ADD_UPDATE_HTTP_SUCCESS = '[Product] Add Update Http Success',
  ADD_UPDATE_HTTP_FAIL = '[Product] Add Update Http Fail',

  DELETE_HTTP = '[Product] Delete Http',
  DELETE_HTTP_SUCCESS = '[Product] Delete Http Success',
  DELETE_HTTP_FAIL = '[Product] Delete Http Fail',

  UPDATE_DATA_LENGTH = '[Product] Update Data Length',
  UPDATE_OFFSET = '[Product] Update Offset',
  UPDATE_LIMIT = '[Product] Update Limit',

  UPDATE_SEARCH = '[Product] Update Search',

}

export class LoadHttp implements Action {
  readonly type = ProductActionType.LOAD_HTTP;
  constructor(public payload?: any) {}
}

export class LoadHttpSuccess implements Action {
  readonly type = ProductActionType.LOAD_HTTP_SUCCESS;
  constructor(public payload: IProduct[]) {}
}
export class LoadHttpFail implements Action {
  readonly type = ProductActionType.LOAD_HTTP_FAIL;
  constructor(public error: any) {}
}

export class AddHttp implements Action {
  readonly type = ProductActionType.ADD_HTTP;
  constructor(public payload: IProduct) {}
}

export class UpdateHttp implements Action {
  readonly type = ProductActionType.UPDATE_HTTP;
  constructor(public payload: IProduct) {}
}

export class AddUpdateHttpSuccess implements Action {
  readonly type = ProductActionType.ADD_HTTP;
  // comes from effect
  constructor(public payload: IProduct) {}
}
export class AddUpdateHttpFail implements Action {
  readonly type = ProductActionType.ADD_UPDATE_HTTP_FAIL;
  constructor(public error: any) {}
}

export class DeleteHttp implements Action {
  readonly type = ProductActionType.DELETE_HTTP;
  constructor(public countryId: number) {}
}

export class DeleteHttpSuccess implements Action {
  readonly type = ProductActionType.DELETE_HTTP_SUCCESS;
  constructor(public payload: any) {}
}

export class DeleteHttpFail implements Action {
  readonly type = ProductActionType.DELETE_HTTP_FAIL;
  constructor(public error: any) {}
}

export class UpdateDataLength implements Action {
  readonly type = ProductActionType.UPDATE_DATA_LENGTH;
  constructor(public dataLength: number) {}
}

export class UpdateOffset implements Action {
  readonly type = ProductActionType.UPDATE_OFFSET;
  constructor() {}
}
export class UpdateLimit implements Action {
  readonly type = ProductActionType.UPDATE_LIMIT;
  constructor(public limit: number) {}
}
export class UpdateSearch implements Action {
  readonly type = ProductActionType.UPDATE_SEARCH;
  constructor(public search: any) { }
}

export type ProductActions =
  | LoadHttp
  | LoadHttpSuccess
  | LoadHttpFail
  | AddHttp
  | UpdateHttp
  | AddUpdateHttpSuccess
  | AddUpdateHttpFail
  | DeleteHttp
  | DeleteHttpSuccess
  | DeleteHttpFail
  | UpdateDataLength
  | UpdateOffset
  | UpdateLimit
  | UpdateSearch;
