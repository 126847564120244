<ion-row>
  <div
    style="font: normal normal 600 18px/12px Cairo;letter-spacing: 0.54px;color: #1E2329;text-transform: capitalize;margin: 20px;"
    *ngIf="isParent">
    {{'All' | translate}}
  </div>
  <div
    style="font: normal normal 600 18px/12px Cairo;letter-spacing: 0.54px;color: #1E2329;text-transform: capitalize;margin: 20px;"
    *ngIf="!isParent">
    {{category.name}}
  </div>
  <ion-col size="12">
    <ion-slides (ionSlideReachEnd)="loadMore($event,category)" style="margin: 20px;" pager="false" scrollbar="true" [options]="{slidesPerView:5}">
      <ion-slide style="display: block" *ngFor="let product of products">
        <ion-row style="display: flex;justify-content: center;">
          <ion-button (click)="openQuickOrder(product.id)" style="width: 120px;height: 120px;" mode="ios">
            <img style="max-width: 300%;"
              [src]="env.url+'/web/image/storex_modules.product/'+ product.id +'/image/120x120'" />
          </ion-button>
        </ion-row>
        <ion-row style="display: flex;justify-content: center;">
          <div>{{product.name}}</div>
        </ion-row>
        <ion-row style="display: flex;justify-content: center;">
          <div>{{product.price}}{{product.currency[0]}}</div>
        </ion-row>
      </ion-slide>
      <ion-slide *ngIf="loading" style="display: block">
        <ion-row style="display: flex;justify-content: center;">
          <ion-spinner></ion-spinner>
        </ion-row>
      </ion-slide>
    </ion-slides>
  </ion-col>
</ion-row>