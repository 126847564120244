import { EntityState, createEntityAdapter } from '@ngrx/entity';
import { EntityAdapter } from '@ngrx/entity/src/models';
import { ICountry } from '../../model';

export interface CountryState extends EntityState<ICountry> {
  loading: boolean;
  uploading: boolean;
  offset: number;
  limit: number;
  dataLength: number;
  countryRegister:any;
  search: any;
}

export const countryAdapter: EntityAdapter<ICountry> = createEntityAdapter<ICountry>();

export const initialState: CountryState = countryAdapter.getInitialState({
  uploading: false,
  loading: false,
  offset: 0,
  dataLength: 0,
  limit: 50,
  search: [],
  countryRegister:[]
});
