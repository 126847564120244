import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { CountryActionsType } from '../actions';
import * as countryActions from '../actions';
import { switchMap, map, catchError, withLatestFrom } from 'rxjs/operators';
import { of } from 'rxjs';
import { OdooAPI } from 'src/app/services/odoo/services/odooAPI';
import { Store } from '@ngrx/store';
import { CountryState } from '..';
import * as CountrySelectors from '../selectors';

@Injectable()
export class CountryEffects {
  constructor(
    private actions$: Actions,
    private odooAPI: OdooAPI,
    private countryStore: Store<CountryState>,
    ) {}

  @Effect()
  loadHttp$ = this.actions$.pipe(
    ofType(CountryActionsType.LOAD_HTTP),
    switchMap((loadHTTPAction: countryActions.LoadHttp) => {
      return of(loadHTTPAction.payload);
    }),
    withLatestFrom(     
      this.countryStore.select(CountrySelectors.selectOffset),
      this.countryStore.select(CountrySelectors.selectLimit),
      this.countryStore.select(CountrySelectors.selectSearch),
    ),
    switchMap(([payload,offset,limit,search]) => {
      return this.odooAPI
        .loadCountry(payload.uid,offset, payload.ids,limit,search)
        .pipe(
          map(
            (data: any) => {
              return new countryActions.LoadHttpSuccess(data.records);
            },
            err => {
              return new countryActions.LoadHttpFail(err);
            }
          ),
        catchError(err => {
          return of(new countryActions.LoadHttpFail(err));
        })
        )
    })
  );

  @Effect()
  loadPublicHttp$ = this.actions$.pipe(
    ofType(CountryActionsType.LOAD_PUBLIC_HTTP),
    switchMap((loadHTTPAction: countryActions.LoadPublicHttp) => {
      return of(loadHTTPAction);
    }),
    switchMap((payload: any) => {
      return this.odooAPI.loadCountryPublic().pipe(
        map(
          (data: any) => {
            return new countryActions.LoadPublicHttpSuccess(data);
          },
          err => {
            return new countryActions.LoadPublicHttpFail(err);
          }
        ),
        catchError((err: any) => {
          return of(new countryActions.LoadPublicHttpFail(err));
        })
      );
    })
  );
  @Effect()
  updateSearch$ = this.actions$.pipe(
    ofType(CountryActionsType.UPDATE_SEARCH),
    switchMap((updateSearchAction: countryActions.UpdateSearch) =>
      of(updateSearchAction.search)
    ),
    switchMap((search: any) => {
      return of(new countryActions.LoadHttp({}));
    })
  );
}
