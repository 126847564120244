<ion-row style="display: flex;align-items: center;" *ngIf="platform.is('desktop')">
  <ion-col style="height: 68px;" size="9">
    <div style="position: absolute;top: 10%;left: 16px;font-size: 25px;z-index: 2;background: #e5e5e5;border-radius: 10%;display: flex;width: 30px;height: 90%;align-items: center;justify-content: center;" (click)="slidePrev()">
      <ion-icon name="caret-back-outline"></ion-icon>
    </div>
    <div style="position: absolute;top: 10%;right: 16px;font-size: 25px;z-index: 2;background: #e5e5e5;border-radius: 10%;display: flex;width: 30px;height: 90%;align-items: center;justify-content: center;" (click)="slideNext()">
      <ion-icon name="caret-forward-outline"></ion-icon>
    </div>
    <ion-slides #slides pager="false" scrollbar="true" [options]="{freeMode:true,slidesPerView:(sharedService.innerWidth<500?2.5:sharedService.innerWidth<1000?3.5:sharedService.innerWidth<1500?5.5:6.5)}">
      <ion-slide style="display: block;">
        <ion-row style="display: block;">
          <ion-button fill="clear" (click)="selectCategory(all)" [ngStyle]="{'background':selected ==all?'#F3A31A':'black'}"
            style="height: 60px; border-radius: 12px;min-width: 80px;" mode="ios">
            <div style="font:normal normal normal 19px/19px Cairo;color: #FFFFFF;">{{'ALL' | translate}}</div>
          </ion-button>
        </ion-row>
      </ion-slide>
      <ion-slide  style="display: block;" *ngFor="let category of categories">
        <ion-row style="display: block;">
          <ion-button  fill="clear" (click)="selectCategory(category.id)"
            [ngStyle]="{'background':selected==category.id?'#F3A31A':'black'}"
            style="min-width: 80px; height: 60px; border-radius: 12px;" mode="ios">
            <ion-row style="display: flex;justify-content: center;align-items: center;">
              <ion-col size="4">
                <img style="width:100%;max-width: 40px;;"
                [src]="env.url+'/web/image/storex_modules.category/'+ category.id +'/image/150x100'" />
              </ion-col>
              <ion-col class="ion-no-padding" size="8">
                <div [ngStyle]="{'font-size':(sharedService.innerWidth<900?12:sharedService.innerWidth/80).toString()+'px'}" style="white-space: pre-wrap; min-width: 12px;color: #ffff;">{{category.name}}</div>
              </ion-col>
            </ion-row>
          </ion-button>
        </ion-row>
      </ion-slide>
    </ion-slides>
  </ion-col>
  
  <ion-col style="height: 68px;" size="3">
    <ion-item lines="none">
      <ion-select
        style="border-radius: 8px;border: 1px solid #3B4046;width: 100%;height: 60px;padding: 10px;" 
        placeholder="{{'SORT_BY' | translate}}" (ionChange)="sortProducts($event)">
        <ion-select-option *ngFor="let value of sortValues" [value]="value">{{
          value.name}}</ion-select-option>
      </ion-select>
    </ion-item>
  </ion-col>
</ion-row>
<ion-row style="display: flex;align-items: center;" *ngIf="platform.is('desktop') && childCategories && childCategories.length > 0">
  <ion-col size="9" style="height: 68px;" >
    <ion-slides style="background-color: #F3A31A;border-radius: 12px;"  pager="false" scrollbar="true" [options]="{slidesPerView:5}">
      <ion-slide style="display: block;" *ngFor="let category of childCategories">
        <ion-row style="display: block;">
          <ion-button fill="clear" (click)="selectCategory(category.id)"
            [ngStyle]="{'background':selected==category.id?'black':'#F3A31A'}"
            style="width: 140px;height: 60px; border-radius: 12px;" mode="ios">
            <ion-row style="display: flex;justify-content: center;align-items: center;">
              <ion-col size="6">
                <div style="font:normal normal normal 19px/19px Cairo;color: #FFFFFF;">{{category.name}}</div>
              </ion-col>
            </ion-row>
          </ion-button>
        </ion-row>
      </ion-slide>
    </ion-slides>
  </ion-col>
  </ion-row>
<ion-slides *ngIf="!platform.is('desktop')" pager="false" scrollbar="true" [options]="{slidesPerView:5.5}">
  <ion-slide style="display: block;">
    <ion-row style="display: block;">
      <ion-button fill="clear" (click)="selectCategory(all)" [ngStyle]="{'background':selected ==all?'#F3A31A':'black'}"
        style="width: 55px;height: 55px; border-radius: 15px;" mode="ios">
      </ion-button>
    </ion-row>
    <ion-row style="display: flex;justify-content: center;font-size: 15px;">
      <div style="width: 75px;">{{'ALL' | translate}}</div>
    </ion-row>
  </ion-slide>
  <ion-slide style="display: block;" *ngFor="let category of categories">
    <ion-row style="display: block;">
      <ion-button fill="clear" (click)="selectCategory(category.id)"
        [ngStyle]="{'background':selected==category.id?'#F3A31A':'black'}"
        style="width: 55px;height: 55px; border-radius: 15px;" mode="ios">
        <img style="max-width: 300%;"
          [src]="env.url+'/web/image/storex_modules.category/'+ category.id +'/image/200x100'" />
      </ion-button>
    </ion-row>
    <ion-row style="display: flex;justify-content: center;font-size: 15px;">
      <div style="width: 75px;">{{category.name}}</div>
    </ion-row>
  </ion-slide>
</ion-slides>
