import { Action } from '@ngrx/store';
import { ICountry } from '../../model';

// HTTP CRUD

//  CRUD
export enum CountryActionsType {
  LOAD_HTTP = '[Country] Load Http',
  LOAD_HTTP_SUCCESS = '[Country] Load Http Success',
  LOAD_HTTP_FAIL = '[Country] Load Http Fail',

  LOAD_PUBLIC_HTTP = '[Country] Load Public Http',
  LOAD_PUBLIC_HTTP_SUCCESS = '[Country] Load Http Public Success',
  LOAD_PUBLIC_HTTP_FAIL = '[Country] Load Public Http Fail',

  ADD_HTTP = '[Country] Add Http',
  UPDATE_HTTP = '[Country] Update Http',
  ADD_UPDATE_HTTP_SUCCESS = '[Country] Add Update Http Success',
  ADD_UPDATE_HTTP_FAIL = '[Country] Add Update Http Fail',

  DELETE_HTTP = '[Country] Delete Http',
  DELETE_HTTP_SUCCESS = '[Country] Delete Http Success',
  DELETE_HTTP_FAIL = '[Country] Delete Http Fail',

  UPDATE_DATA_LENGTH = '[Country] Update Data Length',
  UPDATE_OFFSET = '[Country] Update Offset',
  UPDATE_LIMIT = '[Country] Update Limit',

  UPDATE_SEARCH = '[Country] Update Search',

}

export class LoadHttp implements Action {
  readonly type = CountryActionsType.LOAD_HTTP;
  constructor(public payload: any) {}
}

export class LoadHttpSuccess implements Action {
  readonly type = CountryActionsType.LOAD_HTTP_SUCCESS;
  constructor(public payload: ICountry[]) {}
}
export class LoadHttpFail implements Action {
  readonly type = CountryActionsType.LOAD_HTTP_FAIL;
  constructor(public error: any) {}
}

export class LoadPublicHttp implements Action {
  readonly type = CountryActionsType.LOAD_PUBLIC_HTTP;
  constructor() {}
}

export class LoadPublicHttpSuccess implements Action {
  readonly type = CountryActionsType.LOAD_PUBLIC_HTTP_SUCCESS;
  constructor(public payload: ICountry[]) {}
}
export class LoadPublicHttpFail implements Action {
  readonly type = CountryActionsType.LOAD_PUBLIC_HTTP_FAIL;
  constructor(public error: any) {}
}

export class AddHttp implements Action {
  readonly type = CountryActionsType.ADD_HTTP;
  constructor(public payload: ICountry) {}
}

export class UpdateHttp implements Action {
  readonly type = CountryActionsType.UPDATE_HTTP;
  constructor(public payload: ICountry) {}
}

export class AddUpdateHttpSuccess implements Action {
  readonly type = CountryActionsType.ADD_HTTP;
  // comes from effect
  constructor(public payload: ICountry) {}
}
export class AddUpdateHttpFail implements Action {
  readonly type = CountryActionsType.ADD_UPDATE_HTTP_FAIL;
  constructor(public error: any) {}
}

export class DeleteHttp implements Action {
  readonly type = CountryActionsType.DELETE_HTTP;
  constructor(public countryId: number) {}
}

export class DeleteHttpSuccess implements Action {
  readonly type = CountryActionsType.DELETE_HTTP_SUCCESS;
  constructor(public payload: any) {}
}

export class DeleteHttpFail implements Action {
  readonly type = CountryActionsType.DELETE_HTTP_FAIL;
  constructor(public error: any) {}
}

export class UpdateDataLength implements Action {
  readonly type = CountryActionsType.UPDATE_DATA_LENGTH;
  constructor(public dataLength: number) {}
}

export class UpdateOffset implements Action {
  readonly type = CountryActionsType.UPDATE_OFFSET;
  constructor() {}
}
export class UpdateLimit implements Action {
  readonly type = CountryActionsType.UPDATE_LIMIT;
  constructor(public limit: number) {}
}
export class UpdateSearch implements Action {
  readonly type = CountryActionsType.UPDATE_SEARCH;
  constructor(public search: any) { }
}

export type CountryActions =
  | LoadHttp
  | LoadHttpSuccess
  | LoadHttpFail
  | LoadPublicHttp
  | LoadPublicHttpSuccess
  | LoadPublicHttpFail
  | AddHttp
  | UpdateHttp
  | AddUpdateHttpSuccess
  | AddUpdateHttpFail
  | DeleteHttp
  | DeleteHttpSuccess
  | DeleteHttpFail
  | UpdateDataLength
  | UpdateOffset
  | UpdateLimit
  | UpdateSearch;
