import { EntityState, createEntityAdapter } from '@ngrx/entity';
import { EntityAdapter } from '@ngrx/entity/src/models';
import { IProduct } from '../../model';

export interface ProductState extends EntityState<IProduct> {
  loading: boolean;
  uploading: boolean;
  offset: number;
  limit: number;
  dataLength: number;
  countryRegister:any;
  search: any;
}

export const productAdapter: EntityAdapter<IProduct> = createEntityAdapter<IProduct>();

export const initialState: ProductState = productAdapter.getInitialState({
  uploading: false,
  loading: false,
  offset: 0,
  dataLength: 0,
  limit: 10,
  search: [],
  countryRegister:[]
});
