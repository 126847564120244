import * as fromCategoryActions from '../actions';

import { CategoryState, initialState, CategoryAdapter } from '../state/category.state';

export function CategoryReducer(
  state = initialState,
  action: fromCategoryActions.CategoryActions
): CategoryState {
  switch (action.type) {
    case fromCategoryActions.CategoryActionsType.LOAD_HTTP: {
      return {
        ...state,
        loading: true
      };
    }

    case fromCategoryActions.CategoryActionsType.UPDATE_OFFSET: {
      return {
        ...state,
        offset: state.offset + state.limit
      };
    }

    case fromCategoryActions.CategoryActionsType.UPDATE_LIMIT: {
      return {
        ...state,
        limit: action.limit
      };
    }

    case fromCategoryActions.CategoryActionsType.UPDATE_DATA_LENGTH: {
      return {
        ...state,
        offset: action.dataLength
      };
    }

    case fromCategoryActions.CategoryActionsType.LOAD_HTTP_SUCCESS: {
      
      // add Categorys to entities
      const offset = state.offset + action.payload.length;
      if (state.offset === 0) {
        return {
          ...CategoryAdapter.addAll(action.payload, state),
          loading: false,
          offset
        };
      } else {
        return {
          ...CategoryAdapter.upsertMany(action.payload, state),
          offset,
          loading: false
        };
      }
    }

    case fromCategoryActions.CategoryActionsType.LOAD_HTTP_FAIL: {
      return {
        ...state,
        loading: false
      };
    }

    case fromCategoryActions.CategoryActionsType.ADD_HTTP: {
      return {
        ...state,
        loading: true
      };
    }
    case fromCategoryActions.CategoryActionsType.LOAD_PUBLIC_HTTP_SUCCESS: {
           let CategoryRegister=state.CategoryRegister
           CategoryRegister=action.payload.slice()
           return {...state,CategoryRegister}
          }

    case fromCategoryActions.CategoryActionsType.UPDATE_SEARCH: {
            return {
              ...state,
              search: action.search,
              offset: 0
            };
          }          
  }
  return state;
}
