import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-success-order',
  templateUrl: './success-order.component.html',
  styleUrls: ['./success-order.component.scss'],
})
export class SuccessOrderComponent implements OnInit {

  @Input() order:any;

  constructor(
    private router: Router,
    private modalCtrl: ModalController,

  ) { }

  ngOnInit() {}

  openProductsPage() {
    this.router.navigate(['landing-page'])
    this.modalCtrl.dismiss()
  }
}
