import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { filter, take, takeUntil } from 'rxjs/operators';
import { CategoryState } from 'src/app/stores/category/store';
import * as categorySelector from 'src/app/stores/category/store/selectors'
import * as productSelector from 'src/app/stores/product/store/selectors';
import { ProductState } from 'src/app/stores/product/store';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import * as productActions from 'src/app/stores//product/store/actions';


@Component({
  selector: 'app-default-child-category',
  templateUrl: './default-child-category.component.html',
  styleUrls: ['./default-child-category.component.scss'],
})
export class DefaultChildCategoryComponent implements OnInit, OnDestroy {

  @Input() categoryId;
  category: any;
  products=[];
  destroyed$ = new Subject<any>();
  isParent: any;
  env = environment;
  @Input() selectedStoreValue:string;
  loading: boolean;

  constructor(
    private categoryStore: Store<CategoryState>,
    private productStore: Store<ProductState>,
    private router: Router

  ) { }
  ngOnDestroy(): void {
    this.destroyed$.next()
    this.destroyed$.unsubscribe()
  }

  ngOnInit() {
    this.productStore.dispatch(new productActions.LoadHttp({search:[['delivery_user_id.storex_sub_domain', '=',  window.location.hostname],['category_ids','in',[this.categoryId]]],offset:0}))
    this.categoryStore.select(categorySelector.selectCategoryById(this.categoryId)).pipe(filter(data => data && data.length > 0), take(1)).subscribe(data => {
      this.category = data[0]
      this.isParent = this.category.is_parent
        this.selectProducts()
    })
  }
  openQuickOrder(id) {
    this.router.navigate(['quick-order/' + id])
  }

  loadMore(event,category){
    this.loading=true
    if(this.products.length> 5)
      this.productStore.dispatch(new productActions.LoadHttp({search:[['delivery_user_id.storex_sub_domain', '=',  window.location.hostname],['category_ids','in',[category.id]]],offset:this.products.length}))
    setTimeout(() => {
      this.loading=false
    }, 3000);
  }

  selectProducts() {
    this.productStore.select(productSelector.selectProductByIds(this.category.product_ids)).pipe(filter(data => data && data.length > 0),takeUntil(this.destroyed$)).subscribe(data => {
      this.products = data
      if(this.selectedStoreValue == 'lowprice'){
        this.products = this.products.sort((a,b) => a.price - b.price)
      }
      else if(this.selectedStoreValue == 'highprice'){
        this.products = this.products.sort((a,b) => b.price - a.price)
      }
    })

}
}
