class Environment {

  public production: boolean;
  public websiteTitle: string;
  public db: string;
  public url: string;
  constructor() {
    this.production = false;
    this.websiteTitle="Storex";
    this.db=window.location.hostname.split('.')[0];
    this.url=window.location.protocol+"//"+window.location.hostname.split('.')[0]+".olivery.io"
  }
}

export const environment = new Environment();