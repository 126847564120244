import * as fromProductActions from '../actions';

import { ProductState, initialState, productAdapter } from '../state/product.state';

export function ProductReducer(
  state = initialState,
  action: fromProductActions.ProductActions
): ProductState {
  switch (action.type) {
    case fromProductActions.ProductActionType.LOAD_HTTP: {
      return {
        ...state,
        loading: true
      };
    }

    case fromProductActions.ProductActionType.UPDATE_OFFSET: {
      return {
        ...state,
        offset: state.offset + state.limit
      };
    }

    case fromProductActions.ProductActionType.UPDATE_LIMIT: {
      return {
        ...state,
        limit: action.limit
      };
    }

    case fromProductActions.ProductActionType.UPDATE_DATA_LENGTH: {
      return {
        ...state,
        offset: action.dataLength
      };
    }

    case fromProductActions.ProductActionType.LOAD_HTTP_SUCCESS: {
      // add countrys to entities
      const offset = state.offset + action.payload.length;
      if (state.offset === 0) {
        return {
          ...productAdapter.addAll(action.payload, state),
          loading: false,
          offset
        };
      } 
      else {
        return {
          ...productAdapter.upsertMany(action.payload, state),
          offset,
          loading: false
        };
      }
    }

    case fromProductActions.ProductActionType.LOAD_HTTP_FAIL: {
      return {
        ...state,
        loading: false
      };
    }

    case fromProductActions.ProductActionType.ADD_HTTP: {
      return {
        ...state,
        loading: true
      };
    }

    case fromProductActions.ProductActionType.UPDATE_SEARCH: {
            return {
              ...productAdapter.removeAll(state),
              ...state,
              search: action.search,
              offset: 0
            };
          }          
  }
  return state;
}
