
<ion-content *ngIf="!platform.is('desktop')">
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-button (click)="back()">
        <ion-icon name="arrow-back-outline"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
  <ion-row>
    <ion-col size="12">
      <img style="width: 100%"
        [src]="env.url+'/web/image/storex_modules.product/'+productId+'/image/400x400'" alt="" />
    </ion-col>
  </ion-row>

  <ion-row style="display: flex;align-items: center;">
    <ion-col
      style="font: bold 18px/8px Cairo;letter-spacing: 1.71px;color: #1E2329;text-transform: capitalize;opacity: 1;line-height: 20px;"
      size="5">
      {{product.name}}
    </ion-col>
    <ion-col style="display: flex;justify-content: flex-end;" size="7">
      <ng-container *ngFor="let category of categories">
        <ion-label
          style="margin-inline: 10px;font-size: 20px;color: #F3A31A;text-transform: capitalize;letter-spacing: 1.14px;background-color: #f3a31a4f;border-radius: 5px">
          {{category.name}}</ion-label>
      </ng-container>
    </ion-col>
  </ion-row>
  <form [formGroup]="formGroup">
    <ion-grid>
      <ion-list>
        <ion-item lines="none">
          <ion-label
            style="text-transform: capitalize;letter-spacing: 1.33px;color: #F3A31A;font-size: 20px;margin-bottom: 20px;"
            position="stacked">{{'VARIANTS' | translate}}</ion-label>
          <ion-select *ngIf="productVariants.length > 1" 
            style="border-radius: 8px;border: 1px solid #3B4046;width: 100%;height: 46px;padding: 10px;"
            interface="popover" placeholder="{{'SELECT_VARIANT' | translate}}" (ionChange)="selectVariant($event)">
            <ion-select-option *ngFor="let variant of productVariants" [value]="variant">{{
              variant.name}}</ion-select-option>
          </ion-select>
          <ion-input readonly="true" style="padding: 10px;" *ngIf="productVariants.length == 1"
            [value]="selectedVariant.name">
          </ion-input>
          <ion-input [hidden]="true" type="number" formControlName="product_id">
          </ion-input>
        </ion-item>
        <div class="form-validation-err">
          <ng-container *ngFor="let validation of validation_messages.product_id">
            <div class="error-message" *ngIf="
              formGroup.get('product_id').hasError(validation.type) &&
              (formGroup.get('product_id').dirty ||
                formGroup.get('product_id').touched ||
                submitAttempt)
            ">
              <ion-icon name="information-circle-outline"></ion-icon>
              {{ validation.message | translate }}
            </div>
          </ng-container>
        </div>
        <ion-item lines="none" *ngIf="selectedVariant">
          <ion-label
            style="text-transform: capitalize;letter-spacing: 1.33px;color: #F3A31A;font-size: 20px;margin-bottom: 10px;"
            position="stacked">{{'STOCK' | translate}}</ion-label>
          <ion-row style="border-radius: 8px;border: 1px solid #3B4046;width: 100%;height: 46px;">
            <ion-col size="3" style="display: flex;justify-content: center;align-items: center;">
              <ion-row>
                <ion-icon style="margin-inline: 15px;margin-bottom: 5px;" (click)="qtyPlus()"
                  name="add-outline"></ion-icon>
              </ion-row>
            </ion-col>
            <ion-col style="margin-block: auto;display: flex;justify-content: space-around;" size="6">
              {{quantity}}
            </ion-col>
            <ion-col style="display: flex;justify-content: center;align-items: center;" size="3">
              <ion-row>
                <ion-icon style="margin-inline: 15px;margin-bottom: 5px;" (click)="qtyMenus()"
                  name="remove-outline"></ion-icon>
              </ion-row>
            </ion-col>

          </ion-row>
        </ion-item>
        <ion-item>
          <ion-row style="display: contents;">
            <ion-col
              style="text-transform: capitalize;letter-spacing: 1.33px;color: #F3A31A;font-size: 20px;">{{'PRICE:' |
              translate}}
            </ion-col>
            <ion-col style="margin-inline: 10px;display: flex;justify-content: end;">
              {{product.price}}{{product.currency[0]}} </ion-col>
          </ion-row>
        </ion-item>
        <ion-item *ngIf="!product.matched_currency">
          <ion-row style="display: contents;">
            <ion-col size="12"
              style="font: 16px/11px Cairo;letter-spacing: 0.36px;color: #3B4046;text-transform: capitalize;display: flex;justify-content: center;">
              {{'THIS_PRODUCT_IS_UNAVAILABLE_IN_YOUR_CURRENCY_AND_USE' | translate}}{{product.currency[0]}}</ion-col>
          </ion-row>
        </ion-item>
        <ion-item lines="none">
          <ion-row
            style="font: normal normal bold 16px/8px Cairo;letter-spacing: 1.52px;color: #1E2329;text-transform: capitalize;opacity: 1;margin-top: 30px">
            {{'ORDER_INFORMATION' | translate}}
          </ion-row>
        </ion-item>

        <ion-item lines="none">
          <ion-label
            style="text-transform: capitalize;letter-spacing: 1.33px;color: #F3A31A;font-size: 20px;margin-top: 10px;"
            position="stacked">{{'NAME' | translate}}</ion-label>
          <ion-input
            style="background: #FAFAFA 0% 0% no-repeat padding-box;border: 1px solid #3B4046;border-radius: 8px;margin-top: 15px;padding: 5px !important;"
            type="text" formControlName="client_name"></ion-input>
        </ion-item>

        <div class="form-validation-err">
          <ng-container *ngFor="let validation of validation_messages.client_name">
            <div class="error-message" *ngIf="
                formGroup.get('client_name').hasError(validation.type) &&
                (formGroup.get('client_name').dirty ||
                  formGroup.get('client_name').touched ||
                  submitAttempt)
              ">
              <ion-icon name="information-circle-outline"></ion-icon>
              {{ validation.message | translate }}
            </div>
          </ng-container>
        </div>
        <ion-item lines="none">
          <ion-label
            style="text-transform: capitalize;letter-spacing: 1.33px;color: #F3A31A;font-size: 20px;margin-bottom: 10px;"
            position="stacked">{{'MOBILE_NUMBER' | translate}}</ion-label>
          <ion-input
            style="background: #FAFAFA 0% 0% no-repeat padding-box;border: 1px solid #3B4046;border-radius: 8px;margin-top: 15px;padding: 5px !important;"
            type="tel" required pattern="^[\u0621-\u064A\u0660-\u06690-9 ]+$"
            formControlName="client_mobile"></ion-input>
        </ion-item>

        <div class="form-validation-err">
          <ng-container *ngFor="let validation of validation_messages.client_mobile">
            <div class="error-message" *ngIf="
                formGroup.get('client_mobile').hasError(validation.type) &&
                (formGroup.get('client_mobile').dirty ||
                  formGroup.get('client_mobile').touched ||
                  submitAttempt)
              ">
              <ion-icon name="information-circle-outline"></ion-icon>
              {{ validation.message | translate }}
            </div>
          </ng-container>
        </div>
        <ion-item lines="none">
          <ion-label
            style="text-transform: capitalize;letter-spacing: 1.33px;color: #F3A31A;font-size: 20px;margin-bottom: 20px;"
            position="stacked">{{'AREA' | translate}}</ion-label>
          <ion-select style="border-radius: 8px;border: 1px solid #3B4046;width: 100%;height: 46px;padding: 10px;"
            interface="popover" placeholder="{{'SELECT_AREA' | translate}}" [value]="selectedAreaId" (ionChange)="selectArea($event)">
            <ion-select-option *ngFor="let area of areas" [value]="area.id">{{ area.name}}</ion-select-option>
          </ion-select>
          <ion-input [hidden]="true" type="number" formControlName="client_area">
          </ion-input>
        </ion-item>

        <div class="form-validation-err">
          <ng-container *ngFor="let validation of validation_messages.client_area">
            <div class="error-message" *ngIf="
                formGroup.get('client_area').hasError(validation.type) &&
                (formGroup.get('client_area').dirty ||
                  formGroup.get('client_area').touched ||
                  submitAttempt)
              ">
              <ion-icon name="information-circle-outline"></ion-icon>
              {{ validation.message | translate }}
            </div>
          </ng-container>
        </div>
        <ion-item lines="none">
          <ion-label
            style="text-transform: capitalize;letter-spacing: 1.33px;color: #F3A31A;font-size: 20px;margin-bottom: 10px;"
            position="stacked">{{'ADDRESS' | translate}}</ion-label>
          <ion-input
            style="background: #FAFAFA 0% 0% no-repeat padding-box;border: 1px solid #3B4046;border-radius: 8px;margin-top: 15px;padding: 5px !important;"
            type="text" formControlName="client_address"></ion-input>
        </ion-item>
        <div class="form-validation-err">
          <ng-container *ngFor="let validation of validation_messages.client_address">
            <div class="error-message" *ngIf="
              formGroup.get('client_address').hasError(validation.type) &&
              (formGroup.get('client_address').dirty ||
                formGroup.get('client_address').touched ||
                submitAttempt)
            ">
              <ion-icon name="information-circle-outline"></ion-icon>
              {{ validation.message | translate }}
            </div>
          </ng-container>
        </div>
      </ion-list>
      <ion-row style="display: flex;justify-content: center;margin-top: 20px;">
        <ion-button style="width: 345px;height: 50px;border-radius: 8px;" (click)="sendOrderForm()">
          {{'ORDER_NOW' | translate}}
        </ion-button>

      </ion-row>
    </ion-grid>
  </form>


</ion-content>

<!-- this form for web view  -->

<ion-content *ngIf="platform.is('desktop')">
  <form [formGroup]="formGroup">
    <ion-grid>
      <ion-list>
        <ion-toolbar>
          <ion-buttons slot="start">
            <ion-button (click)="back()">
              <ion-icon name="arrow-back-outline"></ion-icon>
            </ion-button>
          </ion-buttons>
        </ion-toolbar>
        <div style="border-bottom: 2px solid #3B4046;">
          <ion-row style="margin-bottom: 30px;">
            <ion-col size="6">
              <div style="border-bottom: 2px solid #3B40461C;margin-inline: 20px;">
              <ion-row style="display: flex;align-items: center;">
                <ion-col
                  style="font: bold 18px/8px Cairo;letter-spacing: 1.71px;color: #1E2329;text-transform: capitalize;opacity: 1;line-height: 20px;"
                  size="5">
                  {{product.name}}
                </ion-col>
                <ion-col style="display: flex;justify-content: flex-end;" size="7">
                  <ng-container *ngFor="let category of categories">
                    <ion-label
                      style="margin-inline: 10px;font-size: 20px;color: #F3A31A;text-transform: capitalize;letter-spacing: 1.14px;background-color: #f3a31a4f;border-radius: 5px">
                      {{category.name}}</ion-label>
                  </ng-container>
                </ion-col>
              </ion-row>
            </div>
              <ion-item lines="none">
                <ion-label
                  style="text-transform: capitalize;letter-spacing: 1.33px;color: #F3A31A;font-size: 20px;margin-bottom: 20px;"
                  position="stacked">{{'VARIANTS' | translate}}</ion-label>
                <ion-select *ngIf="productVariants.length > 1 "
                  style="border-radius: 8px;border: 1px solid #3B4046;width: 100%;height: 46px;padding: 10px;"
                   mode="ios" placeholder="{{'SELECT_VARIANT' | translate}}" [value]="selectedVariant"
                  (ionChange)="selectVariant($event)">
                  <ion-select-option *ngFor="let variant of productVariants" [value]="variant">{{
                    variant.name}}</ion-select-option>
                </ion-select>
                <ion-input readonly="true" style="padding: 10px;" *ngIf="productVariants.length == 1"
                  [value]="selectedVariant.name">
                </ion-input>
                <ion-input [hidden]="true" type="number" formControlName="product_id">
                </ion-input>
              </ion-item>
              <div class="form-validation-err">
                <ng-container *ngFor="let validation of validation_messages.product_id">
                  <div class="error-message" *ngIf="
                  formGroup.get('product_id').hasError(validation.type) &&
                  (formGroup.get('product_id').dirty ||
                    formGroup.get('product_id').touched ||
                    submitAttempt)
                ">
                    <ion-icon name="information-circle-outline"></ion-icon>
                    {{ validation.message | translate }}
                  </div>
                </ng-container>
              </div>
              <ion-item lines="none" *ngIf="selectedVariant">
                <ion-label
                  style="text-transform: capitalize;letter-spacing: 1.33px;color: #F3A31A;font-size: 20px;margin-bottom: 10px;"
                  position="stacked">{{'STOCK' | translate}}</ion-label>
                <ion-row style="border-radius: 8px;border: 1px solid #3B4046;width: 100%;height: 46px;">
                  <ion-col size="3" style="display: flex;justify-content: center;align-items: center;">
                    <ion-row>
                      <ion-icon style="margin-inline: 15px;margin-bottom: 5px;" (click)="qtyPlus()"
                        name="add-outline"></ion-icon>
                    </ion-row>
                  </ion-col>
                  <ion-col style="margin-block: auto;display: flex;justify-content: space-around;" size="6">
                    {{quantity}}
                  </ion-col>
                  <ion-col style="display: flex;justify-content: center;align-items: center;" size="3">
                    <ion-row>
                      <ion-icon style="margin-inline: 15px;margin-bottom: 5px;" (click)="qtyMenus()"
                        name="remove-outline"></ion-icon>
                    </ion-row>
                  </ion-col>

                </ion-row>
              </ion-item>
              <ion-item>
                <ion-row style="display: contents;">
                  <ion-col
                    style="text-transform: capitalize;letter-spacing: 1.33px;color: #F3A31A;font-size: 20px;">{{'PRICE:'
                    |
                    translate}}
                  </ion-col>
                  <ion-col style="margin-inline: 10px;display: flex;justify-content: end;">
                    {{product.price}}{{product.currency[0]}} </ion-col>
                </ion-row>
              </ion-item>
              <ion-item lines="none" *ngIf="!product.matched_currency">
                <ion-row style="display: contents;">
                  <ion-col size="12"
                    style="font: 16px/11px Cairo;letter-spacing: 0.36px;color: #3B4046;text-transform: capitalize;display: flex;justify-content: center;">
                    {{'THIS_PRODUCT_IS_UNAVAILABLE_IN_YOUR_CURRENCY_AND_USE' |
                    translate}}{{product.currency[0]}}</ion-col>
                </ion-row>
              </ion-item>
            </ion-col>
            <ion-col style="display: flex;justify-content: center;height: 75%;" size="6">
              <img style="width: 75%;" [src]="env.url+'/web/image/storex_modules.product/'+productId+'/image/400x400'"
                alt="" />
            </ion-col>
          </ion-row>
        </div>
        <ion-row style="display: block;">
          <ion-row>
            <ion-item lines="none">
              <ion-row
                style="font: normal normal bold 16px/8px Cairo;letter-spacing: 1.52px;color: #1E2329;text-transform: capitalize;opacity: 1;margin-top: 30px">
                {{'ORDER_INFORMATION' | translate}}
              </ion-row>
            </ion-item>
          </ion-row>
          <ion-row>
            <ion-col size="6">
              <ion-item lines="none">
                <ion-label
                  style="text-transform: capitalize;letter-spacing: 1.33px;color: #F3A31A;font-size: 20px;margin-top: 10px;"
                  position="stacked">{{'NAME' | translate}}</ion-label>
                <ion-input
                  style="background: #FAFAFA 0% 0% no-repeat padding-box;border: 1px solid #3B4046;border-radius: 8px;margin-top: 15px;padding: 5px !important;"
                  type="text" formControlName="client_name"></ion-input>
              </ion-item>

              <div class="form-validation-err">
                <ng-container *ngFor="let validation of validation_messages.client_name">
                  <div class="error-message" *ngIf="
                    formGroup.get('client_name').hasError(validation.type) &&
                    (formGroup.get('client_name').dirty ||
                      formGroup.get('client_name').touched ||
                      submitAttempt)
                  ">
                    <ion-icon name="information-circle-outline"></ion-icon>
                    {{ validation.message | translate }}
                  </div>
                </ng-container>
              </div>
            </ion-col>
            <ion-col size="6">
              <ion-item lines="none">
                <ion-label
                  style="text-transform: capitalize;letter-spacing: 1.33px;color: #F3A31A;font-size: 20px;margin-bottom: 10px;"
                  position="stacked">{{'MOBILE_NUMBER' | translate}}</ion-label>
                <ion-input
                  style="background: #FAFAFA 0% 0% no-repeat padding-box;border: 1px solid #3B4046;border-radius: 8px;margin-top: 15px;padding: 5px !important;"
                  type="tel" required pattern="^[0-9 ]+$"
                  formControlName="client_mobile"></ion-input>
              </ion-item>

              <div class="form-validation-err">
                <ng-container *ngFor="let validation of validation_messages.client_mobile">
                  <div class="error-message" *ngIf="
                    formGroup.get('client_mobile').hasError(validation.type) &&
                    (formGroup.get('client_mobile').dirty ||
                      formGroup.get('client_mobile').touched ||
                      submitAttempt)
                  ">
                    <ion-icon name="information-circle-outline"></ion-icon>
                    {{ validation.message | translate }}
                  </div>
                </ng-container>
              </div>
            </ion-col>
          </ion-row>
          <ion-row>
            <ion-col size="6">
              <ion-item lines="none">
                
                <ion-label
                  style="text-transform: capitalize;letter-spacing: 1.33px;color: #F3A31A;font-size: 20px;margin-bottom: 20px;"
                  position="stacked">{{'AREA' | translate}}</ion-label>
                <ion-select 
                  style="border-radius: 8px;border: 1px solid #3B4046;width: 100%;height: 46px;padding: 10px;"
                   mode="ios" placeholder="{{'SELECT_AREA' | translate}}" [value]="selectedAreaId"
                  (ionChange)="selectArea($event)">
                  <ion-select-option *ngFor="let area of areas" [value]="area.id">{{
                    area.name}}</ion-select-option>
                </ion-select>
                <ion-input [hidden]="true" type="number" formControlName="client_area">
                </ion-input>
              </ion-item>

              <div class="form-validation-err">
                <ng-container *ngFor="let validation of validation_messages.client_area">
                  <div class="error-message" *ngIf="
                    formGroup.get('client_area').hasError(validation.type) &&
                    (formGroup.get('client_area').dirty ||
                      formGroup.get('client_area').touched ||
                      submitAttempt)
                  ">
                    <ion-icon name="information-circle-outline"></ion-icon>
                    {{ validation.message | translate }}
                  </div>
                </ng-container>
              </div>
            </ion-col>
            <ion-col size="6">
              <ion-item lines="none">
                <ion-label
                  style="text-transform: capitalize;letter-spacing: 1.33px;color: #F3A31A;font-size: 20px;margin-bottom: 10px;"
                  position="stacked">{{'ADDRESS' | translate}}</ion-label>
                <ion-input
                  style="background: #FAFAFA 0% 0% no-repeat padding-box;border: 1px solid #3B4046;border-radius: 8px;margin-top: 15px;padding: 5px !important;"
                  type="text" formControlName="client_address"></ion-input>
              </ion-item>
              <div class="form-validation-err">
                <ng-container *ngFor="let validation of validation_messages.client_address">
                  <div class="error-message" *ngIf="
                  formGroup.get('client_address').hasError(validation.type) &&
                  (formGroup.get('client_address').dirty ||
                    formGroup.get('client_address').touched ||
                    submitAttempt)
                ">
                    <ion-icon name="information-circle-outline"></ion-icon>
                    {{ validation.message | translate }}
                  </div>
                </ng-container>
              </div>
            </ion-col>
          </ion-row>
        </ion-row>
        <ion-item lines="none" style="margin-top: 20px;">
          <ion-button style="width: 100%;height: 50px;border-radius: 8px;" (click)="sendOrderForm()">
            {{'ORDER_NOW' | translate}}
          </ion-button>
        </ion-item>
      </ion-list>
    </ion-grid>
  </form>
</ion-content>