import { Component, Input, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import * as bannerSelector from 'src/app/stores/banner/store/selectors';
import { Store } from '@ngrx/store';
import { BannerState } from 'src/app/stores/banner/store';
import { filter, take } from 'rxjs/operators';
import { Platform } from '@ionic/angular';

@Component({
  selector: 'app-default-banner',
  templateUrl: './default-banner.component.html',
  styleUrls: ['./default-banner.component.scss'],
})
export class DefaultBannerComponent implements OnInit {

  banners: any;
  env = environment


  constructor(
    private bannerStore: Store<BannerState>,
    public platform: Platform,

  ) { }

  ngOnInit() {
    this.bannerStore.select(bannerSelector.selectAllData).pipe(filter(data => data && data.length > 0), take(1)).subscribe(data => {
      this.banners = data
    })
  }


}
