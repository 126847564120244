import { CommonModule } from "@angular/common";
import { HttpClient } from "@angular/common/http";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { IonicModule } from "@ionic/angular";
import { TranslateLoader, TranslateModule } from "@ngx-translate/core";
import { HttpLoaderFactory } from "../app.module";
import { DefaultBannerComponent } from "./default-banner/default-banner.component";
import { DefaultCategoryComponent } from "./default-category/default-category.component";
import { DefaultChildCategoryComponent } from "./default-child-category/default-child-category.component";
import { DefaultFilterPageTemplateComponent } from "./default-filter-page-template/default-filter-page-template.component";
import { DefaultLandingPageTemplateComponent } from "./default-landing-page-template/default-landing-page-template.component";
import { DefaultPackagePageTemplateComponent } from "./default-package-page-template/default-package-page-template.component";
import { DefaultProductsComponent } from "./default-products/default-products.component";
import { DefaultQuickOrderTemplateComponent } from "./default-quick-order-template/default-quick-order-template.component";
import { QuickOrderFormComponent } from "./quick-order-form/quick-order-form.component";
import { SuccessOrderComponent } from "./success-order/success-order.component";
import { TemplatesSelectorComponent } from "./templates-selector/templates-selector.component";
import { LoginFormComponent } from "./login-form/login-form.component";


@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        IonicModule, 
        RouterModule,
        ReactiveFormsModule,
        IonicModule,
        TranslateModule.forChild({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        }),
    ],
    exports: [
        TemplatesSelectorComponent,
        DefaultLandingPageTemplateComponent,
        DefaultBannerComponent,
        DefaultCategoryComponent,
        DefaultProductsComponent,
        DefaultChildCategoryComponent,
        DefaultQuickOrderTemplateComponent,
        QuickOrderFormComponent,
        DefaultFilterPageTemplateComponent,
        DefaultPackagePageTemplateComponent,
        SuccessOrderComponent,
        LoginFormComponent
    ],
    declarations: [
        TemplatesSelectorComponent,
        DefaultLandingPageTemplateComponent,
        DefaultBannerComponent,
        DefaultCategoryComponent,
        DefaultProductsComponent,
        DefaultChildCategoryComponent,
        DefaultQuickOrderTemplateComponent,
        QuickOrderFormComponent,
        DefaultFilterPageTemplateComponent,
        DefaultPackagePageTemplateComponent,
        SuccessOrderComponent,
        LoginFormComponent
    ],
    entryComponents: [
        TemplatesSelectorComponent,
        DefaultLandingPageTemplateComponent,
        DefaultBannerComponent,
        DefaultCategoryComponent,
        DefaultProductsComponent,
        DefaultChildCategoryComponent,
        DefaultQuickOrderTemplateComponent,
        QuickOrderFormComponent,
        DefaultFilterPageTemplateComponent,
        DefaultPackagePageTemplateComponent,
        SuccessOrderComponent,
        LoginFormComponent
    ]
  })
  export class sharedComponentModule {}