export interface IProduct {
  id: number;
  name: string;
  image: string;
  description: number;
  price: string;
  stock: string;
  delivery_user_id: number;
  category_ids: string;
  product_description: string;
  product_type: number;
  alternative_products_ids: string;
  accessory_products_ids: string;
  sequence: number;
  products_variant_ids: string;
  share_link: string;
  currency:string;
  matched_currency:boolean;
  variant_image_ids:any[]
  attachment_ids:any[];
  meta_description:string;
}
export const productFields = [
  'id',
  'name',
  'description',
  'price',
  'stock',
  'delivery_user_id',
  'category_ids',
  'product_description',
  'product_type',
  'alternative_products_ids',
  'accessory_products_ids',
  'sequence',
  'products_variant_ids',
  'share_link',
  'variant_image_ids',
  'attachment_ids',
  'meta_description'
];