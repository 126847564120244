import { createFeatureSelector, createSelector } from '@ngrx/store';

import { CategoryState, CategoryAdapter } from '../state/category.state';

export const selectCategoryState = createFeatureSelector<CategoryState>('Category');

export const getCategoryEntities = (state: CategoryState) => {
  // tslint:disable-next-line:no-unused-expression
  state.entities;
};

export const getCategoryLoading = (state: CategoryState) => state.loading;

export const getCategoryUploading = (state: CategoryState) => state.uploading;
export const getOffset = (state: CategoryState) => state.offset;
export const getLimit = (state: CategoryState) => state.limit;
export const getDataLength = (state: CategoryState) => state.dataLength;
export const getCategoryRegister = (state: CategoryState) => state.CategoryRegister;
export const getSearch = (state: CategoryState) => state.search;


const {
  selectEntities,
  selectAll,
  selectTotal
} = CategoryAdapter.getSelectors();

export const selectAllIDS = createSelector(
  selectCategoryState,
  
);

export const selectAllData = createSelector(
  selectCategoryState,
  selectAll
);

export const selectAllEntities = createSelector(
  selectCategoryState,
  selectEntities
);

export const selectOffset = createSelector(
  selectCategoryState,
  getOffset
);
export const selectDataLength = createSelector(
  selectCategoryState,
  getDataLength
);

export const selectLimit = createSelector(
  selectCategoryState,
  getLimit
);

export const selectDataCategoryedById = createSelector(
  selectAllData,
  (data: any) => {
    data.sort((a, b) => b.id - a.id);
    return data;
  }
);
export const selectCategoryById =(id) => createSelector (
  selectAllData,
  
  (data: any) => {
    
    return data.filter(Category=>Category.id == id);
  }
);
export const selectCategoryRegister = createSelector(
  selectCategoryState,
  getCategoryRegister
);
export const selectSearch = createSelector(
  selectCategoryState,
  getSearch
);

export const selectParentCategory =() => createSelector (
  selectAllData,

  (data: any) => {
    
    return data.filter(Category=>Category.is_parent == true);
  }
);
export const selectCategoryByIds =(ids) => createSelector (
  selectAllData,
  
  (data: any) => {
    return data.filter(Category=> ids.includes(Category.id));
  }
);