import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { take } from 'rxjs/operators';
import { PublicOdooRequest } from 'src/app/services/odoo/models/ModelRemoteOdoo';
import { OdooAPI } from 'src/app/services/odoo/services/odooAPI';
import { productFields } from 'src/app/stores/product/model';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-default-landing-page-template',
  templateUrl: './default-landing-page-template.component.html',
  styleUrls: ['./default-landing-page-template.component.scss'],
})
export class DefaultLandingPageTemplateComponent implements OnInit {

  @Input() type: string;
  @Input() template: string;
  @Input() products: any;
  @Input() banners: any;
  @Input() categories: any;
  env = environment
  loadingProducts: any;
  businessName: any;
  querySearchFilter: ConcatArray<string[]>;
  selectedCategoryId: any;
  changigCategory: boolean;
  selectedStoreValue:string;
  changingSort: boolean;
  constructor(
    private odooApi: OdooAPI,

  ) { }

  ngOnInit() {
  }


  changeCategory(event) {
    this.changigCategory = true
    this.selectedCategoryId = event.id
    setTimeout(() => {
      this.changigCategory = false
    }, 500)

  }

  sortProducts(event){
    this.changingSort = true
    this.selectedStoreValue = event.value
    setTimeout(() => {
      this.changingSort = false
    }, 500)
  }
}
