import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { ProductState } from 'src/app/stores/product/store';
import { environment } from 'src/environments/environment';
import * as productSelector from 'src/app/stores/product/store/selectors';
import { filter, skip, skipUntil, take, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { CategoryState } from 'src/app/stores/category/store';
import * as  CategorySelector from 'src/app/stores/category/store/selectors';
import { ActivatedRoute, Router } from '@angular/router';
import { ModalController, Platform } from '@ionic/angular';
import { DefaultQuickOrderTemplateComponent } from '../default-quick-order-template/default-quick-order-template.component';
import { PublicOdooRequest } from 'src/app/services/odoo/models/ModelRemoteOdoo';
import { productFields } from 'src/app/stores/product/model';
import * as productActions from 'src/app/stores//product/store/actions';
import { SharedService } from 'src/app/services/sharedService';



@Component({
  selector: 'app-default-products',
  templateUrl: './default-products.component.html',
  styleUrls: ['./default-products.component.scss'],
})
export class DefaultProductsComponent implements OnInit, OnDestroy {
  @Input() selectedStoreValue: string;
  @Input() selectedCategoryId: any;
  env = environment
  products = [];
  destroyed$ = new Subject<any>();
  init$ = new Subject<any>();
  category: any;
  childCategories = [];
  isLoading: boolean = true;
  prodSubscriber: any;
  constructor(
    private productStore: Store<ProductState>,
    private categoryStore: Store<CategoryState>,
    private router: Router,
    public platform: Platform,
    private modalCtrl: ModalController,
    public sharedService: SharedService,
    private activatedRoute: ActivatedRoute,

  ) { }
  ngOnDestroy(): void {
    this.destroyed$.next()
    this.destroyed$.unsubscribe()
  }
  ngOnInit() {
    
    this.selectProducts()
    this.activatedRoute.queryParams.pipe(take(1)).subscribe((res) => {
      if (res.domain) {
        let domainFilter = JSON.parse(res.domain)
        this.loadProducts( domainFilter)
      }
      else {
        this.loadProducts()
      }
    });
    this.isLoading = true
    if (this.selectedStoreValue) {
      this.sharedService.selectedStoreValue = this.selectedStoreValue
    }
  }

  loadProducts(domainFilter?) {
    if (domainFilter && !this.selectedCategoryId) {
      this.sharedService.productDomian = this.sharedService.productDomian.concat(domainFilter)
    }
    if (this.selectedCategoryId !== "all" && this.selectedCategoryId) {
      this.categoryStore.select(CategorySelector.selectCategoryById(this.selectedCategoryId)).pipe(take(1)).subscribe(data => {
        
        this.category = data[0]
          if (this.category.child_categories.length > 0) {
            this.getChildCategories(this.category.child_categories)
          }
          else {

            this.productStore.dispatch(new productActions.UpdateSearch([['delivery_user_id.storex_sub_domain', '=', window.location.hostname],['category_ids','in',[this.selectedCategoryId]]]))
            this.init$.next()
            this.init$.unsubscribe()
          }
      })
    }
    else if(this.selectedCategoryId == 'all'){
      this.productStore.dispatch(new productActions.UpdateSearch([['delivery_user_id.storex_sub_domain', '=', window.location.hostname]]))
      this.init$.next()
      this.init$.unsubscribe()
    }
    else{
      this.productStore.dispatch(new productActions.UpdateSearch(this.sharedService.productDomian))
      this.init$.next()
      this.init$.unsubscribe()
    }
   
   

  }


  getChildCategories(childIds) {
    this.categoryStore.select(CategorySelector.selectCategoryByIds(childIds)).pipe(filter(data => data && data.length > 0), take(1)).subscribe(data => {
      this.childCategories = data

    })
  }

  openQuickOrder(id) {
    this.router.navigate(['quick-order/' + id])
  }

  sortProduct() {
    if (this.selectedStoreValue && this.products) {
      if (this.selectedStoreValue == 'lowprice') {
        this.products = this.products.sort((a, b) => {
          if (a.price > b.price) {
            return 1;
          }

          if (a.price < b.price) {
            return -1;
          }

          return 0;
        });
      }
      else if (this.selectedStoreValue == 'highprice') {
        this.products = this.products.sort((a, b) => {
          if (b.price > a.price) {
            return 1;
          }

          if (b.price < a.price) {
            return -1;
          }

          return 0;
        })
      }
      if (this.selectedStoreValue == 'newArrive') {
        this.products = this.products.sort((a, b) => {
          if (a.id < b.id) {
            return 1;
          }

          if (a.id > b.id) {
            return -1;
          }

          return 0;
        });
      }
    }
  }

  selectProducts() {

      this.productStore.select(productSelector.selectAllData).pipe( skipUntil(this.init$),takeUntil(this.destroyed$)).subscribe(data => {
        
        this.products = data
        this.sortProduct()
        this.isLoading = false
      })

  }

  loadMoreProducts(event){
      this.productStore.dispatch(new productActions.LoadHttp({}))
      setTimeout(() => {
        event.target.complete();
      }, 1000);
  }

}
